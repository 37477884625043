import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

const SkeletonYogurtCard = () => {
  return (
    <Card
      className="text-center shadow-sm"
      style={{
        width: "100%",
        height: "400px",
        borderRadius: "15px",
        border: "none",
        overflow: "hidden",
        boxShadow: "0 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow
      }}
    >
      <Card.Body
        style={{
          position: "relative",
          padding: "20px",
          //   backgroundImage: recommendations[currentIndex]?.thumbnail
          // ? `url(${recommendations[currentIndex].thumbnail})`
          // : "url(default-image.jpg)", // Provide a fallback image or color
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          overflow: "hidden", // To contain overlay
        }}
      >
        {/* Overlay */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent overlay
            borderRadius: "15px",
            zIndex: 1,
          }}
        ></div>

        {/* <div
          className="d-flex justify-content-center mb-4"
          style={{ zIndex: 2 }}
        >
          {recommendations.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                width: "80px",
                height: "8px",
                backgroundColor: index === currentIndex ? "#FF2C64" : "#666561",
                borderRadius: "5px",
                margin: "0 10px",
                cursor: "pointer",
                zIndex: 2, // Ensure button is above the overlay
              }}
            ></div>
          ))} */}
        {/* </div> */}

        {/* Card Title positioned over the overlay */}
        <Skeleton
          variant="rectangular"
          style={{
            fontSize: "18px",
            marginBottom: "1rem",
            fontFamily: "Poppins",
            zIndex: 2, // Ensure it is above the overlay
            position: "relative", // Allow positioning on top of overlay
          }}
        />

        <Skeleton
          className="px-4 py-2 mt-auto"
          style={{
            borderRadius: "10px",
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontFamily: "Poppins",
            backgroundColor: "#FF2C64",
            border: "none",
            zIndex: 2, // Ensure button is above the overlay
          }}
        />

        {/* Next and Previous buttons with chevron icons */}
        <Skeleton
          variant="circular"
          width={40} // Width of the skeleton
          height={40} // Height of the skeleton
          style={{
            position: "absolute",
            top: "50%",
            left: "2px",
            zIndex: 2,
            transform: "translateY(-50%)",
            background: "none",
            border: "none",
          }}
        >
          <ChevronLeft fontSize="large" color="#fff" />
        </Skeleton>

        <Skeleton
          variant="circular"
          width={40}
          height={40}
          style={{
            position: "absolute",
            top: "50%",
            right: "2px",
            zIndex: 2,
            transform: "translateY(-50%)",
            background: "none",
            border: "none",
          }}
        >
          <ChevronRight fontSize="large" color="#fff" />
        </Skeleton>
      </Card.Body>
    </Card>
  );
};

export default SkeletonYogurtCard;
