import { Typography } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LandingPageCard from "../../components/cards/LandingPageCard";
import imagineLanding from "./imagineLanding.jpeg";
import "../../assets/fonts/fonts.css";

const LandingPageHow = () => {
  return (
    <Container id="how" style={{ marginTop: 160 }}>
      <Row>
        <Col>
          <Typography
            sx={{
              fontFamily: "National2Bold",
              fontWeight: 700,
              color: "#000",
              fontSize: 40,
            }}
          >
            Cum facem asta?
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col xs={12} lg={7}>
          <Row>
            <Col xs={12} lg={8}>
              <LandingPageCard
                header={
                  "Planuri de mese personalizate pentru nevoile tale de sănătate"
                }
                content={
                  "Endometrioză, PCOS, balonare sau lipsă de energie? Găsește echilibrul cu planuri nutriționale personalizate."
                }
              />
              <LandingPageCard
                header={"Verificate de profesioniști"}
                content={"Nutriționiști certificați îți verifică rețetele."}
              />
              <LandingPageCard
                header={"Ingrediente aduse la tine acasă"}
                content={
                  "Poți comanda ingredientele din plan direct prin platformă."
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={5} className="text-center">
          <div
            className="embed-responsive embed-responsive-16by9"
            style={{ height: "100%" }}
          >
            <img
              className="embed-responsive-item"
              style={{ borderRadius: 10, height: "100%", width: "100%" }}
              src={imagineLanding} // Replace with your imported image URL
              alt="Description of the image" // Provide a description for accessibility
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageHow;
