import React from "react";
import { Image } from "react-bootstrap";

const StepCard = ({ step, image, title, description, style }) => {
  return (
    <div className="text-center">
      <div
        style={{
          position: "relative",
          display: "inline-block",
          marginBottom: "20px",
        }}
      >
        <Image
          src={image}
          style={style}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "0%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FF2C64",
            color: "white",
            borderRadius: "10px",
            width: "32px",
            height: "32px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          {step}
        </div>
      </div>
    </div>
  );
};

export default StepCard;
