import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Stack,
  Spinner,
  Alert,
} from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import axios from "axios";
import imagine from "./imagine.jpeg";
import { Divider, Typography } from "@mui/material";
import { Box, styled, useMediaQuery } from "@mui/system";
import Tick from "../../components/buttons/Tick";
import HomeNavbar from "../../components/navbar/HomeNavbar";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const handleSubmit = async () => {
    // if (!validated) return;

    setLoading(true);
    setErrorMessage(null);

    const query = `
    mutation RequestPasswordResetMutation($email: String!) {
      forgotPassword(email: $email) {
        success
        error
      }
    }
  `;

    const variables = {
      email: email, // Change this to match your input name if needed
    };


    try {
      const response = await axios.post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/", // Your GraphQL endpoint
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data.data.forgotPassword;
      const success = data.success
      const error = data.error
      console.log(data)

      if (error) {
        setErrorMessage("Email-ul dvs nu este în baza de date.")
      }
      // Assuming the token is returned in the response data
      
    } catch (error) {
        console.log(error)
      setErrorMessage(
        error.response?.data?.errors[0]?.message ||
          "A apărut o eroare la autentificare."
      );
    } finally {
      setLoading(false);
    }
  };

  const clickSubmit = (event) => {
    event.preventDefault(); // Prevent form's default submission

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    // handleSubmit();
  };

  return (
    <Container
      fluid
      style={{
        minWidth: "100%",
        padding: 0,
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundColor: "#FFFDF2",
      }}
    >
      {isMobile && (
        <>
          <HomeNavbar color={"#0C3919"} />
          <hr style={{ height: 1, backgroundColor: "#000", border: "none" }} />
        </>
      )}

      <Row>
        <Col
          className="d-none d-lg-block"
          lg={6}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              overflowY: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className=" align-items-center text-white">
                <Col lg={10}>
                  <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo>

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>

        <Col
          style={{
            backgroundColor: "#FFFDF2",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row
            className="align-items-center"
            style={{ padding: 20, marginBottom: "80px" }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "40px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Bine ai revenit!
            </Typography>

            <Row className="mt-4">
              <Col lg={8}>
                <Form noValidate validated={validated} onSubmit={clickSubmit}>
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label
                        htmlFor="email"
                        style={{
                          color: "#D3D3D3",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                      >
                        Email
                      </Form.Label>
                      <InputGroup className="mt-2 mb-3">
                        <Form.Control
                          id="email"
                          style={{
                            backgroundColor: "transparent",
                            fontFamily: "Poppins",
                          }}
                          type="email"
                          placeholder="nume@email.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ fontFamily: "Poppins" }}
                        >
                          Email-ul este obligatoriu.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  
                  <ButtonPrimary
                    type="submit"
                    className="mt-2 mb-2"
                    text={loading ? <Spinner animation="border" /> : "Trimite link"}
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  />
                </Form>

                {errorMessage && (
                  <Alert
                    style={{ fontFamily: "Poppins" }}
                    variant="danger"
                    className="mt-2"
                  >
                    {errorMessage}
                  </Alert>
                )}

                <Typography
                  className="mt-2"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "italic",
                    color: "#D3D3D3",
                  }}
                >
                  Ți-ai reamintit parola? Apasă aici ca să te .{" "}
                  <a
                    style={{ color: "#FF2C64", cursor: "pointer" }}
                    onClick={() => navigate("/login")}
                  >
                    loghezi.
                  </a>
                </Typography>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
