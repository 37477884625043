import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { Row, Col, Stack, Card } from "react-bootstrap";

const SkeletonRecipeCard = ({ isDesktop }) => {
  return (
    <Card
      className="rounded-3 mb-2"
      style={{
        backgroundColor: "#FFFEF7",
        fontFamily: "Poppins",
        border: "none",
        boxShadow: "5px 5px 5px 5px #f0f0f0",
      }}
    >
      <Row className="g-0">
        <Col xs={6} md={4} className="p-0">
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            style={{
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          />
        </Col>
        <Col
          xs={6}
          md={8}
          className="d-flex flex-column justify-content-between"
        >
          <Card.Body className="p-3 d-flex flex-column">
            <div>
              {!isDesktop ? (
                <Skeleton width="60%" height={20} />
              ) : (
                <>
                  <Skeleton width="80%" height={30} />
                  <Skeleton width="90%" height={15} />
                </>
              )}
            </div>
            <Stack gap={2} direction="horizontal" className="mt-auto">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton
                  variant="text"
                  width={30}
                  height={20}
                  className="ms-1"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton
                  variant="text"
                  width={30}
                  height={20}
                  className="ms-1"
                />
              </div>

              {isDesktop && (
                <>
                  <Box
                    className="ms-auto"
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton variant="circular" width={16} height={16} />
                  </Box>
                  <Box
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton variant="circular" width={16} height={16} />
                  </Box>
                </>
              )}

              {!isDesktop && (
                <>
                  <Box
                    style={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton variant="circular" width={10} height={10} />
                  </Box>
                  <Box
                    style={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton variant="circular" width={10} height={10} />
                  </Box>
                </>
              )}
            </Stack>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default SkeletonRecipeCard;
