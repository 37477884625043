import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Stack, Image
} from "react-bootstrap";
import imagine from "./imagine.jpeg";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, styled } from "@mui/system";
import Tick from "../../components/buttons/Tick";
import ButtonForm from "../../components/buttons/ButtonForm";
import Back from "../../components/buttons/Back";
import BoxNumber from "../../components/buttons/BoxNumber";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import BoxNumberOutlined from "../../components/buttons/BoxNumberOutlined";
import { useNavigate } from "react-router-dom";
import RegisterState from "../../context/RegisterState";
import logo from './logoActual2.png';

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const QuestionaireAge = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1200px)");
  const registerState = useContext(RegisterState);
  const [age, setAge] = useState(registerState.age);
  const [gender, setGender] = useState(registerState.gender);
  const [ageError, setAgeError] = useState(false);
  const [genderError, setGenderError] = useState(false);

  const handleSubmit = () => {
    let valid = true;

    // Validate age
    if (!age || age <= 0) {
      setAgeError(true);
      valid = false;
    } else {
      setAgeError(false);
    }

    // Validate gender
    if (!gender) {
      setGenderError(true);
      valid = false;
    } else {
      setGenderError(false);
    }

    if (valid) {
      registerState.setAge(age);
      registerState.setGender(gender);
      navigate("/questionaire/dietary-preferences");
    }
  };

  return (
    <Container
      fluid
      style={{
        minHeight: "100vh",
        display: "flex",
        padding: 0,
        overflowX: "hidden",
        overflowY: "hidden",
        width: "100%",
      }}
    >
      <Row>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              overflowY: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className=" align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                            <Image src={logo} width={"120"} onClick={() => navigate("/")}/>


                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col lg={8} xs={12} style={{ backgroundColor: "#FFFDF2" }}>
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            {!isMobile ? (
              <Stack direction="horizontal" alignItems="center">
                <Back onClick={() => navigate("/")} />

                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumber text={"1"} />
                  <Box sx={{ width: 50, height: "5px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 50, height: "5px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"3"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"4"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <BoxNumberOutlined text={"8"} />

                <ButtonPrimary
                  onClick={() => handleSubmit()}
                  className="ms-auto"
                  text="Continuă"
                />
              </Stack>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                sx={{ width: "100%", padding: "16px", boxSizing: "border-box" }} // Optional padding for aesthetics
              >
                <Stack
                  direction="horizontal"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    maxWidth: "800px",
                    width: "100%",
                    overflow: "hidden",
                    flexWrap: "wrap", // Allow wrapping for smaller screens
                    gap: "4px", // Add some space between boxes
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <BoxNumber text={"1"} />
                    <Box sx={{ width: 10, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <Box display="flex" alignItems="center">
                    <BoxNumberOutlined text={"2"} />
                    <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <Box display="flex" alignItems="center">
                    <BoxNumberOutlined text={"3"} />
                    <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <Box display="flex" alignItems="center">
                    <BoxNumberOutlined text={"4"} />
                    <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <Box display="flex" alignItems="center">
                    <BoxNumberOutlined text={"5"} />
                    <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <Box display="flex" alignItems="center">
                    <BoxNumberOutlined text={"6"} />
                    <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <Box display="flex" alignItems="center">
                    <BoxNumberOutlined text={"7"} />
                    <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                  </Box>

                  <BoxNumberOutlined text={"8"} />
                </Stack>
              </Box>
            )}
          </Row>

          <Row style={{ padding: 20, marginLeft: !isMobile ? 80 : 0 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Care este vârsta ta?
            </Typography>
            <InputGroup
              className="mt-2 mb-3"
              style={{
                width: "100%", // Full width by default on mobile
                maxWidth: "400px", // Make sure it's flexible
              }}
            >
              <Form.Control
                style={{
                  backgroundColor: "transparent",
                  borderColor: ageError ? "red" : "#ced4da", // Add red border on error
                }}
                type="number"
                aria-label="Amount (to the nearest dollar)"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
              <InputGroup.Text
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "Poppins",
                }}
              >
                ani
              </InputGroup.Text>
            </InputGroup>
            <Typography
              className="mt-4"
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Care este genul tău?
            </Typography>
            <Stack className="mt-4">
              <ButtonForm
                width={120}
                text="Masculin"
                onClick={() => setGender("Male")}
                style={{
                  backgroundColor:
                    gender === "Male" ? "#117233" : "transparent",
                  color: gender === "Male" ? "#fff" : "#000",
                  borderColor:
                    genderError && gender === ""
                      ? "red"
                      : gender === "Male"
                      ? "#117233"
                      : "#000", // Red border if gender not selected
                }}
              />
              <ButtonForm
                width={120}
                className="mt-3"
                text="Feminin"
                onClick={() => setGender("Female")}
                style={{
                  backgroundColor:
                    gender === "Female" ? "#117233" : "transparent",
                  color: gender === "Female" ? "#fff" : "#000",
                  borderColor:
                    genderError && gender === ""
                      ? "red"
                      : gender === "Female"
                      ? "#117233"
                      : "#000", // Red border if gender not selected
                }}
              />
              <ButtonForm
                width={120}
                className="mt-3"
                text="Altele"
                onClick={() => setGender("Intersex")}
                style={{
                  backgroundColor:
                    gender === "Intersex" ? "#117233" : "transparent",
                  color: gender === "Intersex" ? "#fff" : "#000",
                  borderColor:
                    genderError && gender === ""
                      ? "red"
                      : gender === "Intersex"
                      ? "#117233"
                      : "#000", // Red border if gender not selected
                }}
              />
            </Stack>
          </Row>
        </Col>
        {isMobile ? (
          <Row
            style={{
              // marginTop: 20,
              paddingTop: 10,
              // paddingLeft: 20,
              // paddingRight: 20,
              paddingBottom: 10,
              boxShadow: "0px 5px 5px 10px #f0f0f0",
              backgroundColor: "#FFFDF2",
              position: "fixed",
              bottom: 0,
              width: "100%",
              left: 0,
              right: 0,
              margin: 0,
            }}
          >
            <Stack direction="horizontal" className="footer">
              <Back onClick={() => navigate("/")} />

              <ButtonPrimary
                onClick={() => handleSubmit()}
                className="ms-auto"
                text="Continuă"
              />
            </Stack>
          </Row>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};

export default QuestionaireAge;
