import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Typography } from "@mui/material";
import { Box, styled, useMediaQuery } from "@mui/system";
import imagine from "./imagine.jpeg"; // Keep the background image if needed
import HomeNavbar from "../../components/navbar/HomeNavbar";
import Tick from "../../components/buttons/Tick";
import logo from "./logoActual2.png";
import { useNavigate } from "react-router-dom";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();
  return (
    <Container
      fluid
      style={{
        minWidth: "100%",
        padding: 0,
        overflowX: "hidden",
        backgroundColor: "#FFFDF2",
      }}
    >
      {isMobile && (
        <>
          <HomeNavbar color={"#0C3919"} />
          <hr style={{ height: 1, backgroundColor: "#000", border: "none" }} />
        </>
      )}

      <Row style={{ height: "100vh" }}>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className="align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                  <Image
                    src={logo}
                    width={"120"}
                    onClick={() => navigate("/")}
                  />

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col
          style={{
            backgroundColor: "#FFFDF2",
            height: "100vh",
            display: "block", // Change this to block to avoid flex alignment
            overflowY: "auto", // Enable vertical scrolling
            padding: 20, // Add padding for some spacing
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "40px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Politica de confidențialitate
          </Typography>
          <Row
            style={{
              marginTop: "20px",
            }}
            className="mt-4"
          >
            <Col xs={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Bloomplate SRL se angajează să protejeze confidențialitatea
                datelor tale cu caracter personal în conformitate cu
                Regulamentul (UE) 2016/679 privind protecția datelor ("GDPR") și
                legislația națională în vigoare. Colectăm date precum sexul,
                vârsta, preferințele alimentare și alte informații necesare
                pentru a personaliza planurile alimentare și a îmbunătăți
                experiența pe site. Aceste informații sunt folosite strict
                pentru a îmbunătăți experiența utilizatorilor și nu sunt
                partajate cu terți, cu excepția cazurilor necesare pentru
                furnizarea serviciilor sau conformitatea legală.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Scopurile Prelucrării Datelor
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Informațiile colectate sunt folosite pentru:
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    marginTop: "5px",
                  }}
                >
                  <li>Personalizarea și furnizarea planurilor alimentare.</li>
                  <li>Procesarea comenzilor și livrarea serviciilor.</li>
                  <li>Comunicare și suport pentru utilizatori.</li>
                  <li>Optimizarea activităților de marketing și promovare.</li>
                </ul>
              </Typography>

              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Tipuri de Date Colectate
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Datele colectate includ:
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    marginTop: "5px",
                  }}
                >
                  <li>
                    Date personale: nume, prenume, adresă de e-mail, număr de
                    telefon, adresa IP.
                  </li>
                  <li>
                    Date specifice: sex, vârstă, preferințe alimentare,
                    obiceiuri alimentare.
                  </li>
                  <li>
                    Date financiare: informații bancare pentru procesarea
                    plăților.
                  </li>
                  <li>Optimizarea activităților de marketing și promovare.</li>
                </ul>
                Aceste informații sunt colectate prin chestionare, crearea
                contului sau plasarea unei comenzi și sunt utilizate exclusiv
                pentru scopuri bine definite, cum ar fi personalizarea
                serviciilor, procesarea comenzilor și optimizarea activităților
                de marketing. Nu colectăm sau prelucrăm date ale minorilor sub
                18 ani.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Securitatea Datelor
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Bloomplate SRL implementează măsuri tehnice și organizatorice
                pentru a proteja datele tale personale împotriva accesului
                neautorizat, pierderii, distrugerii sau divulgării. Datele sunt
                păstrate numai pe durata necesară pentru scopurile declarate.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Drepturile Utilizatorilor
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Utilizatorii au dreptul de a solicita accesul la datele lor,
                rectificarea sau ștergerea acestora. De asemenea, pot retrage
                consimțământul pentru utilizarea datelor în scopuri de marketing
                în orice moment.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Divulgarea Datelor
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Bloomplate SRL poate divulga datele tale personale către terți
                numai dacă este necesar pentru furnizarea serviciilor (ex.
                companii de curierat, furnizori de servicii de plată) sau dacă
                legea o impune. Ne asigurăm că toți partenerii noștri respectă
                cerințele GDPR și oferă garanții adecvate pentru protecția
                datelor tale.
              </Typography>

              <Typography
                className="mt-4"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "40px",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Politica de Refund – BLOOMPLATE S.R.L.
              </Typography>

              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Returnarea Serviciilor
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Serviciile furnizate de BLOOMPLATE S.R.L. prin intermediul
                website-ului www.bloomplate.com, cum ar fi planurile alimentare
                personalizate, sunt produse digitale livrate imediat după
                achiziție. Odată ce planul nutrițional este trimis
                utilizatorului, serviciul este considerat complet furnizat. Din
                acest motiv, conform legislației aplicabile în România,
                serviciile digitale livrate nu sunt eligibile pentru refund.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Excepții și Circumstanțe Speciale
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                În cazul în care utilizatorul consideră că serviciul primit nu
                corespunde cu descrierea sau există alte probleme majore legate
                de serviciul oferit, vom analiza fiecare cerere de refund pe
                bază individuală. În astfel de situații, vă rugăm să ne
                contactați la adresa bloomplatesocial@gmail.com, menționând
                detaliile problemei întâmpinate. BLOOMPLATE S.R.L. nu este
                responsabilă pentru neconformitățile apărute în urma ignorării
                sau nerespectării recomandărilor furnizate în planurile
                alimentare, inclusiv în cazurile în care utilizatorii nu pot sau
                nu doresc să urmeze planul recomandat. Refund-urile nu vor fi
                acordate pentru astfel de cazuri.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Termen pentru Solicitarea Refundului
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Toate solicitările de refund trebuie efectuate în termen de 14
                zile de la data achiziției. După expirarea acestui termen,
                BLOOMPLATE S.R.L. nu va mai putea lua în considerare cererile de
                returnare a banilor.
              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Procesul de Refund
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                În cazul în care cererea de refund este aprobată, suma
                corespunzătoare va fi rambursată prin intermediul metodei
                inițiale de plată, în termen de 14 zile de la aprobarea
                solicitării.
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
