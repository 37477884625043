import React from 'react';
import { Container } from 'react-bootstrap';
import '../../assets/fonts/fonts.css';

const StyledText = ({ color, fontSize, fontWeight, textNormal, textUnderlined }) => {
  return (
    <Container>
      <h5 style={{ fontWeight: 'bold', display: 'inline', fontFamily: 'National2Bold', fontSize: fontSize, fontWeight: fontWeight }}>
        {textNormal}{' '}
        <span
          style={{
            background: `linear-gradient(to top, ${color} 50%, transparent 50%)`,
            borderRadius: '4px',
            padding: '0 4px',
            display: 'inline-block',
          }}
        >
          {textUnderlined}.
        </span>
      </h5>
    </Container>
  );
};

export default StyledText;
