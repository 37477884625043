import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Typography } from "@mui/material";
import { Box, styled, useMediaQuery } from "@mui/system";
import imagine from "./imagine.jpeg"; // Keep the background image if needed
import HomeNavbar from "../../components/navbar/HomeNavbar";
import Tick from "../../components/buttons/Tick";
import logo from "./logoActual2.png";
import { useNavigate } from "react-router-dom";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const CookiePolicy = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();
  return (
    <Container
      fluid
      style={{
        minWidth: "100%",
        padding: 0,
        overflowX: "hidden",
        backgroundColor: "#FFFDF2",
      }}
    >
      {isMobile && (
        <>
          <HomeNavbar color={"#0C3919"} />
          <hr style={{ height: 1, backgroundColor: "#000", border: "none" }} />
        </>
      )}

      <Row style={{ height: "100vh" }}>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className="align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                  <Image
                    src={logo}
                    width={"120"}
                    onClick={() => navigate("/")}
                  />

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col
          style={{
            backgroundColor: "#FFFDF2",
            height: "100vh",
            display: "block", // Change this to block to avoid flex alignment
            overflowY: "auto", // Enable vertical scrolling
            padding: 20, // Add padding for some spacing
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "40px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Politica cookies
          </Typography>
          <Row
            style={{
              marginTop: "20px",
            }}
            className="mt-4"
          >
            <Col xs={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Website-ul nostru utilizează cookie-uri pentru a colecta date despre comportamentul utilizatorilor și pentru a personaliza experiența pe site. Cookie-urile esențiale sunt necesare pentru funcționarea site-ului, în timp ce cookie-urile non-esențiale sunt folosite pentru analiză și marketing. Utilizatorii pot gestiona setările cookie-urilor din browserul lor, dar refuzarea acestora poate afecta funcționalitatea site-ului.

              </Typography>
              <Typography
                className="mt-4 mb-2"
                style={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Modificări ale Politicii de Confidențialitate

              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "#000",
                }}
              >
                Bloomplate SRL își rezervă dreptul de a modifica această politică de confidențialitate pentru a reflecta schimbările în practicile noastre de prelucrare a datelor sau modificările legislative. Te încurajăm să revizuiești periodic această politică pentru a fi la curent cu eventualele modificări.

                
              </Typography>

              
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CookiePolicy;
