import "./App.css";
import FoodPage from "./screens/dashboard/FoodPage";
import Dashboard from "./screens/dashboard/Dashboard";
import DashboardMeniuMese from "./screens/dashboard/DashboardMeniuMese";
import LandingPage from "./screens/landingPage/LandingPage";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ShoppingList from "./screens/dashboard/ShoppingList";
import QuestionaireAge from "./screens/questionaire/QuestionaireAge";
import QuestionaireDietaryPreferences from "./screens/questionaire/QuestionaireDietaryPreferences";
import QuestionaireMainObjective from "./screens/questionaire/QuestionaireMainObjective";
import QuestionaireRestrictions from "./screens/questionaire/QuestionaireRestrictions";
import QuestionaireTime from "./screens/questionaire/QuestionaireTime";
import QuestionaireDiverse from "./screens/questionaire/QuestionaireDiverse";
import QuestionaireKcal from "./screens/questionaire/QuestionaireKcal";
import QuestionaireSubscription from "./screens/questionaire/QuestionaireSubscription";
import QuestionaireRegister from "./screens/questionaire/QuestionaireRegister";
import ProfileSettings from "./screens/profile/ProfileSettings";
import DashboardDesktop from "./screens/dashboard/DashboardDesktop";
import ShoppingListDesktop from "./screens/dashboard/ShoppingListDesktop";
import RoleBasedRedirect from "./router/RoleBaseRedirect";
import LoginPage from "./screens/landingPage/LoginPage";
import AddMeniuMese from "./screens/dashboard/AddMeniuMese";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentComponent from "./screens/questionaire/StripePayment";
import RecommendationPage from "./components/cards/RecommendationPage";
import PrivacyPolicy from "./screens/landingPage/PrivacyPolicy";
import TermsOfUse from "./screens/landingPage/TermsOfUse";
import CookiePolicy from "./screens/landingPage/CookiePolicy";
import ProtectedRoute from "./router/ProtectedRoute";
import ForgotPassword from "./screens/landingPage/ForgotPassword";
import ResetPassword from "./screens/landingPage/ResetPassword";
import AllRecommendationsPage from "./screens/dashboard/AllRecommendationsPage";

const stripePromise = loadStripe(
  "pk_test_51Q4m19FNee4CoG4rzMA4TA63Y3KwEGhYCS0WWgMXFCc85lsAvl4XAAfigBfh7g21aHryxJyierIEc89iQfNZwAg800DcaDyqcU"
);

const options = {
  mode: "payment",
  amount: 10,
  currency: "ron",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

function App() {
  return (
    <Routes>
      <Route path="/landing-page" element={<LandingPage />} />
      <Route path="/" element={<RoleBasedRedirect />} />
      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/desktop"
        element={
          <ProtectedRoute>
            <DashboardDesktop />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/meniu-mese"
        element={
          <ProtectedRoute>
            <DashboardMeniuMese />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/meniu-mese-add"
        element={
          <ProtectedRoute>
            <AddMeniuMese />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/pagina-mancare/:id"
        element={
          <ProtectedRoute>
            <FoodPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/shopping-list"
        element={
          <ProtectedRoute>
            <ShoppingList />
          </ProtectedRoute>
        }
      />
      <Route path="/questionaire/age" element={<QuestionaireAge />} />
      <Route
        path="/questionaire/dietary-preferences"
        element={<QuestionaireDietaryPreferences />}
      />
      <Route
        path="/questionaire/main-objective"
        element={<QuestionaireMainObjective />}
      />
      <Route
        path="/questionaire/restrictions"
        element={<QuestionaireRestrictions />}
      />
      <Route path="/questionaire/time" element={<QuestionaireTime />} />
      <Route path="/questionaire/diverse" element={<QuestionaireDiverse />} />
      <Route path="/questionaire/kcal" element={<QuestionaireKcal />} />
      <Route
        path="/questionaire/subscription"
        element={<QuestionaireSubscription />}
      />
      <Route path="/questionaire/register" element={<QuestionaireRegister />} />
      <Route path="/profile/settings" element={<ProfileSettings />} />
      <Route
        path="/dashboard/shopping-list/desktop"
        element={<ShoppingListDesktop />}
      />
      <Route path="/payment" element={<PaymentComponent />} />
      <Route
        path="/dashboard/recomandare/:id"
        element={
          <ProtectedRoute>
            <RecommendationPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/recomandari"
        element={
          <ProtectedRoute>
            <AllRecommendationsPage />
          </ProtectedRoute>
        }
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
    </Routes>
  );
}

export default App;
