import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from "react-router-dom";
import { Spinner } from "react-bootstrap"; // Import the Spinner component

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useContext(AuthContext);
  
    /** Redirect to the login page if the user is not logged in
     * and tries to access a page meant for logged-in users.
     */

    if (isLoading) {
        return (
            <div style={styles.spinnerContainer}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }
    
    if (!isAuthenticated) {
        return <Navigate to="/landing-page" />;
    }
  
    // Redirect to the selected page
    return children;
};

const styles = {
    spinnerContainer: {
        display: 'inline-flex', // Use inline-flex for inline display
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height to center vertically
        width: '100%', // Full width to center horizontally
    },
};

export default ProtectedRoute;
