import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const Exit = () => {
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        backgroundColor: "#FF2C64",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <CloseIcon sx={{ color: "#fff" }} />
    </Box>
  );
};

export default Exit;
