import React from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Typography } from "@mui/material";

const BoxNumber = ({ text }) => {
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        backgroundColor: "#FF2C64",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={{color: '#fff', fontFamily: 'Poppins'}}>
        { text }
      </Typography>
    </Box>
  );
};

export default BoxNumber;
