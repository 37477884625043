import React from "react";
import { Card } from "react-bootstrap";
import "./SpecialistCard.css";
import { Typography } from "@mui/material";

const SpecialistCard = ({ specialist }) => {
  return (
    <>
    <Card
      className="custom-card"
      style={{ fontFamily: "Poppins", borderRadius: "20px" }}
    >
      <Card.Img
        variant="top"
        src={specialist.image}
        className="custom-card-img"
        width={500}
        height={350}
      />
      <Card.ImgOverlay className="text-overlay">
        <div className="card-body">
          <Card.Title className="card-title">{specialist.position}</Card.Title>
          <Card.Text className="card-name">{specialist.name}</Card.Text>
        </div>
        <div className="bottom-text">b*</div>
      </Card.ImgOverlay>
    </Card>
    <Typography className="mt-3" style={{fontFamily: 'Poppins', fontSize: 16, fontWeight: 300, marginRight: 40}}>
      {specialist.description}
    </Typography>
    </>
  );
};

export default SpecialistCard;
