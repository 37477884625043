import { Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Container, Row, Col, Button, Stack } from 'react-bootstrap';
import Slider from 'react-slick';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TestimonialCard from '../../components/cards/TestimonialCard';
import '../../assets/fonts/fonts.css';

const LandingPageTestimonials = () => {
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
      const previous = () => {
        sliderRef.slickPrev();
    };

    const cards = [
        {
            noStars: 5,
            header: 'Bloomplate mi-a oferit mese echilibrate și delicioase!',
            content: 'Bloomplate mi-a oferit mese echilibrate și delicioase!Bloomplate mi-a oferit o soluție sănătoasă și ușoară pentru a avea mese echilibrate și delicioase. Mi-a plăcut diversitatea rețetelor și simplitatea planurilor. Recomand platforma pentru oricine își dorește să își îmbunătățească stilul de viață!',
            name: 'Alexandru'
        },
        {
            noStars: 5,
            header: 'Cu Bloomplate am economisit mult timp',
            content: 'Cu Bloomplate am economisit mult timp	Experiența a fost minunată, am redus foarte mult timpul de căutat rețete specifice antibalonarii, dar și proteice precum și pentru crearea listei de cumpărături. Rețetele au fost foarte ușor de gătit, uneori pregăteam și în avans pe 2-3 zile ceea ce pe noi ne-a ajutat enorm având un program mai încărcat.',
            name: 'Cristina'
        },
        {
            noStars: 5,
            header: 'Flexibilitate pentru diete speciale',
            content: 'Flexibilitate pentru diete speciale	Experiența a fost una pozitivă, rețete faine, diversificate și gustoase. Nu am apucat să mănânc tot într-o săptămână, așa că am urmat planul mai mult timp. Mă bucur că există astfel de planuri și pentru cei cu diete alimentare speciale (fără gluten, fără zahăr, fără lactoză etc.).',
            name: 'Raluca'
        },
        {
            noStars: 5,
            header: 'Calitatea preparatelor m-a impresionat',
            content: 'Calitatea preparatelor m-a impresionat	Am avut o experiență foarte plăcută! Meniurile sunt excelente și variate, oferind opțiuni delicioase pentru toate gusturile. Deși nu ne-am ținut strict la carte, am savurat micile dejun gustoase, wrapurile proaspete, puiul fraged și budinca de chia sănătoasă. Recomand cu căldură Bloomplate pentru calitatea și diversitatea preparatelor oferite!',
            name: 'Natalia'
        },
    ]

	
	
    const settings = {
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        infinite: true,
        dots: false,
        arrows: false,
        centerMode: true,  // Enable center mode
        centerPadding: "20%", // Adjust padding to show half of the next slide
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0%'
                    // centerPadding: "10%", // Adjust padding for mobile view
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // centerPadding: "15%", // Adjust padding for tablet view
                }
            }
        ]
    };
    return (
        <Container style={{marginTop: 160}}>
            <Row>
                <Col>
                    <Typography sx={{fontFamily: 'National2Bold', fontWeight: 700, color: '#000', fontSize: 40}}>
                        Tastymoniale apetisante!
                    </Typography>
                </Col>
            </Row>
            <Stack className='mt-5 mb-3'>

                <div className='ms-auto'>
                    <Button style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }} onClick={previous}>
                        <ChevronLeftIcon sx={{color: '#FF2C64', fontSize: '32px'}}/>
                    </Button>
                    <Button style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }} onClick={next}>
                        <ChevronRightIcon sx={{color: '#FF2C64', fontSize: '32px', marginLeft: '8px'}}/>
                    </Button>
                </div>
            </Stack>
            <Row>
                <Slider ref={slider => {
                                sliderRef = slider;
                            }}
                        {...settings}>
                        <TestimonialCard name={cards[0].name} content={cards[0].content} header={cards[0].header} noStars={cards[0].noStars}/>
                        <TestimonialCard name={cards[1].name} content={cards[1].content} header={cards[1].header} noStars={cards[1].noStars}/>
                        <TestimonialCard name={cards[2].name} content={cards[2].content} header={cards[2].header} noStars={cards[2].noStars}/>
                        <TestimonialCard name={cards[3].name} content={cards[3].content} header={cards[3].header} noStars={cards[3].noStars}/>

                </Slider>
            </Row>
        </Container>
    );
};

export default LandingPageTestimonials;