import React from "react";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const Tick = () => {
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        backgroundColor: "#DCF552",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <CheckIcon sx={{ color: "#000" }} />
    </Box>
  );
};

export default Tick;
