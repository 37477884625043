import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import StyledText from "../../components/texts/StyledTextHalfColored";
import { Typography } from "@mui/material";
import picture1 from "./aiazic.png";
import picture2 from "./Icon.png";
import Exit from "../../components/buttons/Exit";
import Tick from "../../components/buttons/Tick";
import '../../assets/fonts/fonts.css';

const LandingPageDream = () => {
  return (
    <Container style={{ marginTop: 160 }}>
      <Row className="text-center">
        <StyledText
          color={"#DCF552"}
          fontSize={40}
          fontWeight={700}
          textNormal={"Nu,"}
          textUnderlined={"nu este un vis"}
        />
      </Row>
      <Row>
        <Col lg={6} xs={12} className="mt-5">
          <Exit />
          <Typography
            className="text-center mt-2 mb-3"
            sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: "bold" }}
          >
            Înainte aveai de făcut 3 pași care îți luau <br /> timp, energie și mulți
            mulți bani...
          </Typography>
          <Row className="mb-3" style={{ textAlign: "center", marginTop: 50 }}>
            <div style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Image
                src={picture1}
                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} // Maintain aspect ratio
              />
            </div>
          </Row>
        </Col>

        <Col lg={6} xs={12} className="mt-5">
          <Tick />
          <Typography
            className="text-center mt-2 mb-3"
            sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: "bold" }}
          >
            Acum ai un singur pas de făcut pentru <br /> a avea grijă de alimentația
            ta.
          </Typography>
          <Row className="mt-3 mb-3" style={{ textAlign: "center" }}>
            <div style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Image
                src={picture2}
                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} // Maintain aspect ratio
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageDream;
