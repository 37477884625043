import { Avatar, Skeleton, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./DashboardSidebar.css";
import AssignmentIcon from "@mui/icons-material/Assignment";

import {
  Search as SearchIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from "@mui/icons-material";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import { Box } from "@mui/system";
import { Card } from "react-bootstrap";
import ReplayIcon from "@mui/icons-material/Replay";
import { AuthContext } from "../../context/AuthContext";
const MeniuMeseCard = ({ onClick, image }) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  let width = "100%";
  if (!isMobile) {
    width = "200px";
  }
  return (
    <Card
      onClick={onClick}
      className="text-center shadow-sm"
      style={{
        minHeight: "200px",
        width: width,
        borderRadius: "15px",
        border: "none",
        overflow: "hidden",
      }}
    >
      <Card.Body
        style={{
          position: "relative",
          padding: "10px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      >
        <Stack direction="horizontal" gap={2} style={{ float: "right" }}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: "#fff",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <FavoriteBorderIcon sx={{ color: "#FF2C64" }} />
          </Box>
          <Box
            className="ms-auto"
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: "#FF2C64",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <ReplayIcon sx={{ color: "#fff" }} />
          </Box>
        </Stack>
      </Card.Body>
    </Card>
  );
};

const AddMeniuMese = ({}) => {
  const { logout } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);
  const [mealsLoading, setMealsLoading] = useState(false);
  const location = useLocation();
  const { day } = location.state || 0;
  const { mealType } = location.state || 0;

  const isMobile = useMediaQuery("(max-width:1000px)");
  const token = Cookies.get("authToken");
  const [meals, setMeals] = useState([]);
  const navigate = useNavigate();

  const handleReplace = (newMealId) => {
    let mealType2 = "";
    if (mealType === "snacks") {
      mealType2 = "snack";
    } else {
      mealType2 = mealType;
    }
    let data = {
      query: `
        mutation($mealId: ID!, $day: Int!, $mealType: String!) {
          addMeal(mealId: $mealId, day: $day, mealType: $mealType) {
            success
            error
            title
            summary
            imageUrl
            readyInMinutes
            ingredientsCount
          }
        }
      `,
      variables: {
        mealId: newMealId,
        day: day,
        mealType: mealType2,
      },
    };

    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        data,
        {
          headers: {
            Authorization: `JWT ` + token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        const { success, error } = response.data.data.addMeal;
        if (success) {
          const newMeal = {
            id: newMealId,
            title: response.data.data.addMeal.title,
            summary: response.data.data.addMeal.summary,
            image_url: response.data.data.addMeal.imageUrl,
            ready_in_minutes: response.data.data.addMeal.readyInMinutes,
            ingredients_count: response.data.data.addMeal.ingredientsCount,
          };
          // Step 1: Retrieve cached data from sessionStorage
          const cachedData =
            JSON.parse(sessionStorage.getItem("combinedUserData")) || {};

          const mealsForDay =
            JSON.parse(cachedData.weekData.mealsForWeek)[day] || {};
          if (!mealsForDay[mealType2]) {
            mealsForDay[mealType2] = newMeal; // Add new category if it doesn't exist
          } else {
            // If the category exists, you can choose to replace it or add to it
            // Here, we simply replace it. Adjust logic if you want to append instead.
            mealsForDay[mealType2] = newMeal;
          }

          console.log(mealsForDay);
          cachedData.weekData.mealsForWeek = JSON.stringify({
            ...JSON.parse(cachedData.weekData.mealsForWeek),
            [day]: mealsForDay,
          });

          // console.log(cachedData);

          // Step 5: Store the updated cachedData back in sessionStorage
          sessionStorage.setItem("combinedUserData", JSON.stringify(cachedData));
          navigate("/dashboard");
        } else {
          console.error("Error adding meal:", error);
        }
      })
      .catch((error) => {
        console.error("Error fetching meals:", error);
        console.log(data);
      });
  };

  function renderMealType(mealType) {
    if (mealType.includes("breakfast")) {
      return "Mic Dejun";
    } else if (mealType.includes("lunch")) {
      return "Pranz";
    } else if (mealType.includes("dinner")) {
      return "Cina";
    }
    return mealType; // Return original if no matches found
  }

  useEffect(() => {
    let mealTypeUrl = "";
    if (mealType === "breakfast") {
      mealTypeUrl = "mic dejun";
    } else if (mealType === "lunch") {
      mealTypeUrl = "pranz";
    } else if (mealType === "dinner") {
      mealTypeUrl = "cina";
    } else if (mealType === "snacks") {
      mealTypeUrl = "snacks";
    }

    const fetchMeals = async (day, mealTypeUrl) => {
      setMealsLoading(true);
      const query = `
          query($day: Int!, $mealType: String!) {
            mealsNotInMealList(day: $day, mealType: $mealType) {
              meals {
                id
                title
                imageUrl
              }
              username
            }
          }
        `;

      const variables = {
        day: day,
        mealType: mealTypeUrl,
      };

      console.log(variables);

      try {
        const response = await axios.post(
          "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
          {
            query, // Send the query here
            variables, // Send the variables here
          },
          {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
        );

        console.log(response); // Log the response data to inspect

        // Extract meals and username from response
        if (response.data.data && response.data.data.mealsNotInMealList) {
          const { meals, username } = response.data.data.mealsNotInMealList;
          setMeals(meals);
          setUsername(username);
        } else {
          console.error("Invalid response structure:", response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setMealsLoading(false); // Ensure loading state is reset
      }
    };

    // Ensure that day is defined and valid before calling fetchMeals
    if (day) {
      fetchMeals(day, mealTypeUrl);
    }
  }, [day, mealType]);

  if (isMobile) {
    return (
      <Container fluid style={{ width: "100%", backgroundColor: "#FFFDF2" }}>
        <Row>
          <DashboardSidebar />
        </Row>

        <Row>
          <Col
            style={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Meniu Mese
            </Typography>

            {/* <Stack className="mt-2" direction="horizontal" gap={2}>
              <Button
                variant="outline-dark"
                style={{
                  borderRadius: "20px",
                  border: "1px solid black",
                  padding: "4px 8px",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SearchIcon style={{ fontSize: "16px", marginRight: "4px" }} />
                Cauta
              </Button>

              <Button
                variant="outline-dark"
                style={{
                  borderRadius: "20px",
                  border: "1px solid black",
                  padding: "4px 8px",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FavoriteBorderIcon
                  style={{ fontSize: "16px", marginRight: "4px" }}
                />
                Preferate
              </Button>
            </Stack> */}
          </Col>
        </Row>
        <Row style={{ padding: "20px" }} className="mt-2">
          <Typography
            sx={{ fontSize: "26px", fontFamily: "Poppins", fontWeight: "bold" }}
          >
            {renderMealType(mealType)}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontFamily: "Poppins" }}>
            Recomandări pentru săptămâna asta
          </Typography>
        </Row>
        <Row>
          {mealsLoading ? (
            <Row className="text-center">
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    // width: ,
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    // width: ,
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    // width: ,
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            meals.map((meal, index) => (
              <Col xs={6} lg={3} className="mb-5">
                <div key={index}>
                  <MeniuMeseCard
                    image={meal.imageUrl}
                    onClick={() => handleReplace(meal.id)}
                  />
                  <Typography
                    className="mt-2"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: "12px",
                      paddingRight: 8,
                    }}
                  >
                    {meal.title ? (
                      <>{meal.title}</>
                    ) : (
                      "Aici o să vină titlul unei rețete"
                    )}
                  </Typography>
                </div>
              </Col>
            ))
          )}
        </Row>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        style={{
          backgroundColor: "#E7EFDF",
        }}
      >
        {" "}
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                N
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked
                  ? "#117233"
                  : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandari
            </Button>
            {/* </Nav> */}
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>

          <Col lg={10} className="p-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
                marginRight: 10,
                borderRadius: "40px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                Meniu Mese
              </Typography>

              {/* <Stack className="mt-2 mb-5" direction="horizontal" gap={2}>
                <Button
                  variant="outline-dark"
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    padding: "4px 8px",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SearchIcon
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                  Cauta
                </Button>

                <Button
                  variant="outline-dark"
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    padding: "4px 8px",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FavoriteBorderIcon
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                  Preferate
                </Button>
              </Stack> */}
              <Row className="mt-2 mb-2">
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {renderMealType(mealType)}
                </Typography>
                <Typography sx={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Recomandări pentru săptămâna asta
                </Typography>
              </Row>
              <Row>
                {mealsLoading ? (
                  <Row className="text-center">
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          // width: ,
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          // width: ,
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          // width: ,
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  meals.map((meal, index) => (
                    <Col lg={3} xs={6} className="mb-5">
                      <div key={index}>
                        <MeniuMeseCard
                          image={meal.imageUrl}
                          onClick={() => handleReplace(meal.id)}
                        />
                        <Typography
                          className="mt-2"
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            fontSize: "12px",
                            paddingRight: 8,
                          }}
                        >
                          {meal.title ? (
                            <>{meal.title}</>
                          ) : (
                            "Aici o să vină titlul unei rețete"
                          )}
                        </Typography>
                      </div>
                    </Col>
                  ))
                )}
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default AddMeniuMese;
