import React from "react";
import { Card, Image } from "react-bootstrap";
import { Box } from "@mui/system";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import plus from "./plus.png";

const AddRecipeCard = ({ mealType, day }) => {
  const navigate = useNavigate();

  return (
    <Card
      className="rounded-3 mb-2"
      style={{
        border: "1px dashed gray", // Dotted gray border
        borderStyle: "dashed", // Ensure dashed style
        borderWidth: "1px", // Thinner border
        backgroundColor: "transparent", // Make background transparent
        height: "140px", // Set height
        display: "flex", // Use flex to center the plus sign
        alignItems: "center", // Center vertically
        justifyContent: "center", // Center horizontally
        fontFamily: "Poppins",
      }}
    >
      <Box
        style={{
          fontSize: "40px", // Reduce the plus sign size
          color: "gray", // Color of the plus sign
          fontWeight: "300", // Lighter font weight for thinner appearance
        }}
      >
        {/* <FaPlus
          
        /> */}
        <Image
          onClick={() =>
            navigate("/dashboard/meniu-mese-add", {
              state: { day, mealType },
            })
          }
          src={plus}
          height={80}
        />
      </Box>
    </Card>
  );
};

export default AddRecipeCard;
