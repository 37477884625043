import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import Tick from "../buttons/Tick";
import { useNavigate } from "react-router-dom";

const LandingPageCard = ({ header, content }) => {
  return (
    <Card
      style={{
        padding: "16px",
        backgroundColor: "#FFFDF2",
        border: 0,
        fontFamily: "Poppins",
      }}
    >
      <Row>
        <Col xs="auto">
          <Tick />
        </Col>
        <Col>
          <Card.Title style={{ fontWeight: "bold", fontSize: "24px" }}>
            {header}
          </Card.Title>
          <Card.Text style={{ color: "#6c757d", fontSize: "16px" }}>
            {content}
          </Card.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default LandingPageCard;
