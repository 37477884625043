import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ButtonSecondary from "../buttons/ButtonSecondary";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const HomeFooter = () => {
  const navigate = useNavigate();
  return (
    <footer
      style={{
        backgroundColor: "#0C3919",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
      className="text-white py-4"
    >
      <Container>
        <Row>
          <Col xs={12} lg={4} className="mt-4">
            <Typography
              variant="h5"
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              bloomplate*
            </Typography>
            <Typography style={{ fontFamily: "Poppins", marginTop: 20 }}>
              Bloomplate este platforma care simplifică mâncatul sănătos ca tu
              să poți avea un stil de viață echilibrat.
            </Typography>
          </Col>

          <Col className="mb-3 mt-4" xs={12} lg={3}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Secțiuni
            </Typography>
            <Row>
              <Col>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("#how")}
                >
                  Ce facem
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("#how2")}
                >
                  Cum funcționează
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("#nutritie")}
                >
                  Nutriționiști
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("#preturi")}
                >
                  Prețuri
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("#faq")}
                >
                  FAQ
                </Typography>
              </Col>
            </Row>
          </Col>

          <Col className="mb-3 mt-4" xs={12} lg={3}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Social Media
            </Typography>
            <Row>
              <Col>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://www.instagram.com/bloomplate/")
                  }
                >
                  Instagram
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://www.tiktok.com/@bloomplate")
                  }
                >
                  TikTok
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://www.facebook.com/bloomplate/")
                  }
                >
                  Facebook
                </Typography>
              </Col>
            </Row>
          </Col>

          <Col xs={12} lg={2} className="mt-4">
            <Row>
              <Col xs={12} className="mb-2">
                <ButtonPrimary
                  onClick={() => navigate("/questionaire/age")}
                  text="Start chestionar"
                />
              </Col>
              <Col xs={12}>
                <ButtonSecondary
                  onClick={() => navigate("/login")}
                  text="Login"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={12}>
            <Typography
              style={{ fontFamily: "Poppins", textDecoration: "none" }}
              className="text-white d-inline"
            >
              © Bloomplate SRL
            </Typography>

            <Typography
              style={{
                fontFamily: "Poppins",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("/privacy-policy")}
              className="text-white d-inline ms-3"
            >
              Privacy policy
            </Typography>

            <Typography
              style={{
                fontFamily: "Poppins",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("/terms-of-use")}
              className="text-white d-inline ms-3"
            >
              Terms of use
            </Typography>

            <Typography
              style={{
                fontFamily: "Poppins",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("/cookie-policy")}
              className="text-white d-inline ms-3"
            >
              Cookie policy
            </Typography>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default HomeFooter;
