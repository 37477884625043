import { Avatar, Skeleton, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Stack, Card } from "react-bootstrap";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import { Box } from "@mui/system";
import axios from "axios";

const Recommendation = ({ onClick, image, title }) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  let width = "100%";
  if (!isMobile) {
    width = "200px";
  }
  return (
    <div>
      <Card
        onClick={onClick}
        className="text-center shadow-sm"
        style={{
          minHeight: "250px", // slightly increased to account for title area
          width: width,
          borderRadius: "15px",
          border: "none",
          overflow: "hidden",
        }}
      >
        <Card.Body
          style={{
            position: "relative",
            padding: "10px",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        ></Card.Body>
        <Card.Footer
          style={{
            backgroundColor: "transparent",
            padding: "10px",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal", // allows line wrapping
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4, // limits to 2 lines with ellipsis if needed
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "12px",
            color: "black", // adjust as needed
          }}
        >
          {title ? title : "Aici o să vină titlul unei rețete"}
        </Card.Footer>
      </Card>
    </div>
  );
};

const AllRecommendationsPage = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);
  const token = Cookies.get("authToken");
  const [username, setUsername] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFilteredRecommendations = async () => {
    setIsLoading(true);
    const cachedData =
      JSON.parse(sessionStorage.getItem("combinedUserData")) || {};

    if (cachedData.weekData) {
      setUsername(cachedData.weekData.username);
    }

    const existingRecommendations = sessionStorage.getItem("recommendations");
    if (existingRecommendations) {
      setIsLoading(false);
      return JSON.parse(existingRecommendations);
    }
    const query = `
      query GetFilteredRecommendations {
        recommendations {
          id
          title
          shortDescription
          username
          thumbnail
          author
          content
        }
      }
    `;

    try {
      const response = await axios.post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query },
        { headers: { Authorization: `JWT ${token}` } }
      );
      sessionStorage.setItem("recommendations", JSON.stringify(response.data.data.recommendations));
      setIsLoading(false);
      return response.data.data.recommendations;
    } catch (error) {
      console.error("Error fetching filtered recommendations:", error);
    }
  };

  useEffect(() => {
    fetchFilteredRecommendations().then((data) => {
      setRecommendations(data);
      setUsername(data[0].username);
    });
  }, []);

  if (isMobile) {
    return (
      <Container fluid style={{ width: "100%", backgroundColor: "#FFFEF7" }}>
        <Row>
          <DashboardSidebar />
        </Row>
        <Row>
          <Col
            style={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Recomandari
            </Typography>
          </Col>
        </Row>
        <Row className="mt-2">
          {isLoading ? (
            <Row className="text-center">
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            recommendations.map((recommendation) => (
              <Col key={recommendation.id} className="mb-5" xs={6} lg={3}>
                <Recommendation
                  onClick={() =>
                    navigate(`/dashboard/recomandare/${recommendation.id}`)
                  }
                  image={recommendation.thumbnail}
                  title={recommendation.title}
                />
              </Col>
            ))
          )}
        </Row>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        style={{
          backgroundColor: "#E7EFDF",
        }}
      >
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                border: "1px solid #000",
                color: "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandari
            </Button>
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>
          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginRight: 10,
                borderRadius: "20px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                Recomandari
              </Typography>
              <Row className="mt-2">
                {isLoading ? (
                  <Row className="text-center">
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  recommendations.map((recommendation) => (
                    <Col key={recommendation.id} className="mb-5" xs={6} lg={3}>
                      <Recommendation
                        onClick={() =>
                          navigate(
                            `/dashboard/recomandare/${recommendation.id}`
                          )
                        }
                        image={recommendation.thumbnail}
                        title={recommendation.title}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default AllRecommendationsPage;
