import React from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Typography } from "@mui/material";

const BoxNumberOutlined = ({ onClick }) => {
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        backgroundColor: "transparent",
        borderRadius: "4px",
        border: '1px solid #FF2C64',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",  // Add cursor pointer

      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon  fontSize="2" sx={{color: '#FF2C64'}}/>
    </Box>
  );
};

export default BoxNumberOutlined;
