import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const StyledButton = styled(Button)(({ marginleft }) => ({
  color: "#fff",
  borderColor: "#FFFDF2",
  fontFamily: "Poppins",
  fontSize: 16,
  fontWeight: 400,
  borderRadius: 10,
  textTransform: "none",
  padding: "8px 16px",
  marginLeft: marginleft || 0,
  minWidth: 120,
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#FFFDF2",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const ButtonSecondary = ({ text, marginLeft, className, onClick, href }) => {
  return (
    <StyledButton
      variant="outlined"
      size="medium"
      disableElevation
      marginleft={marginLeft}
      className={className}
      component={href ? "a" : "button"}
      href={href || undefined}
      onClick={!href ? onClick : undefined} // Only use onClick if no href is provided
    >
      {text}
    </StyledButton>
  );
};

ButtonSecondary.propTypes = {
  text: PropTypes.string.isRequired,
  marginLeft: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string, // Add href prop
};

ButtonSecondary.defaultProps = {
  marginLeft: "0",
  className: "",
  onClick: null,
  href: null, // Default href is null
};

export default ButtonSecondary;
