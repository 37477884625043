import React from "react";
import ButtonSecondary from "./ButtonSecondary"; // Adjust the path as necessary
import { styled } from "@mui/system";

const BlackButtonStyled = styled(ButtonSecondary)(({ marginLeft }) => ({
  color: "#000", // Black color for text
  borderColor: "#000", // Black color for border
  "&:hover": {
    borderColor: "#000", // Keep border black on hover
    backgroundColor: "transparent", // Maintain transparent background on hover
  },
  marginLeft: marginLeft || 0, // Allow marginLeft prop
}));

const BlackButton = ({ text, marginLeft, className, onClick, href }) => {
  return (
    <BlackButtonStyled
      text={text}
      marginLeft={marginLeft}
      className={className}
      onClick={onClick}
      href={href}
    />
  );
};

export default BlackButton;
