import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";

const LandingPageCardSecondary = ({ content }) => {
  return (
    <Card
      style={{
        paddingRight: "16px",
        paddingBottom: "16px",
        backgroundColor: "#FFFDF2",
        border: 0,
        fontFamily: "Poppins",
      }}
    >
      <Row>
        <Col xs="auto">
          <div
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#DCF552",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckIcon style={{ color: "#000", fontSize: "24px" }} />
          </div>
        </Col>
        <Col>
          <Card.Text style={{ color: "#6c757d", fontSize: "16px" }}>
            {content}
          </Card.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default LandingPageCardSecondary;
