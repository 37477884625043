import { Typography, useMediaQuery } from "@mui/material";
import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import StyledText from "../../components/texts/StyledTextHalfColored";
import StepCard from "../../components/cards/StepCard";
import imageCard1 from "./imageCard1.png";
import imageCard2 from "./imageCard2.png";
import imageCard3 from "./imageCard3.jpeg";
import imageCard4 from "./imageCard4.png";
import imageCard5 from "./image5.jpg";
import '../../assets/fonts/fonts.css';

import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import "./LandingPageEasyPeasy.css";
import { Navigate, useNavigate } from "react-router-dom";

const LandingPageEasyPeasy = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();

  const steps = [
    {
      step: 1,
      image: imageCard1,
      title: "Completezi formularul",
      description: "Îți personalizezi experiența completând chestionarul",
    },
    {
      step: 2,
      image: imageCard2,
      title: "Primești planul alimentar",
      description: "Noi îți dăm un plan săptămânal potrivit nevoilor tale.",
    },
    {
      step: 3,
      image: imageCard3,
      title: "Comanzi ingredientele",
      description:
        "Ne putem ocupa de aducerea ingredientelor prin partenerii noștri",
    },
    {
      step: 4,
      image: imageCard4,
      title: "Gătești masa delicioasă și sănătoasă",
      description: "Iei ingredientele și prepari cu ușurință mesele",
    },
    {
      step: 5,
      image:
        "https://media.istockphoto.com/id/1496615445/photo/portrait-of-beautiful-happy-woman-smiling-during-sunset-outdoor.jpg?s=612x612&w=0&k=20&c=_HXfCjbresNg_9Y-z1XHrw-JPBzov9l39RK_8Qmv7nk=",
      title: "Îți atingi obiectivele și te simți sănătoasă",
      description:
        "Evoluezi în cea mai bună versiune a ta, plină de energie și vitalitate",
    },
  ];

  if (!isMobile) {
    return (
      <Container id="how2" style={{ marginTop: 160 }}>
        <Row className="text-center">
          <StyledText
            color={"#DCF552"}
            fontSize={40}
            fontWeight={700}
            textNormal={"Gata cât ai spune"}
            textUnderlined={"easy peasy"}
          />
        </Row>
        <div className="dotted-line-container">
          <Row className=" mt-5">
            {steps.map((step, index) => (
              <Col key={index}>
                <StepCard
                  step={step.step}
                  image={step.image}
                  title={step.title}
                  description={step.description}
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "40px",
                    objectFit: "cover", // or 'contain' depending on your requirement
                  }}
                />

                <Typography
                  className="text-center mb-3 mt-2"
                  sx={{ fontWeight: "bold", fontFamily: "Poppins" }}
                >
                  {step.title}
                </Typography>
                <Typography
                  className="text-center mb-3"
                  sx={{ fontFamily: "Poppins", fontSize: 12 }}
                >
                  {step.description}
                </Typography>
              </Col>
            ))}
          </Row>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            left: 0,
          }}
        >
          <ButtonPrimary text="Start chestionar" onClick={() => navigate("/questionaire/age")} />
        </div>
      </Container>
    );
  } else {
    return (
      <Container id="how2" style={{ marginTop: 160 }}>
        <Row className="text-center">
          <StyledText
            color={"#DCF552"}
            fontSize={40}
            fontWeight={700}
            textNormal={"Gata cât ai spune"}
            textUnderlined={"easy peasy"}
          />
        </Row>
        <div className="dotted-line-container-vertical">
          <Row className="mt-5">
            {steps.map((step, index) => (
              <>
                <Col className="mb-2" style={{justifyContent: 'center', alignItems: 'center'}} xs={4} key={index}>
                  <StepCard
                    step={step.step}
                    image={step.image}
                    title={step.title}
                    description={step.description}
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "15px",
                      objectFit: "cover", // or 'contain' depending on your requirement
                    }}
                  />
                </Col>
                <Col style={{paddingLeft: 20}} xs={8}>
                  <Typography
                    className="mb-3 mt-2"
                    sx={{ fontWeight: "bold", fontFamily: "Poppins" }}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    className="mb-3"
                    sx={{ fontFamily: "Poppins", fontSize: 12 }}
                  >
                    {step.description}
                  </Typography>
                </Col>
              </>
            ))}
          </Row>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            left: 0,
          }}
        >
          <ButtonPrimary text="Start chestionar" onClick={() => navigate("/questionaire/age")} />
        </div>
      </Container>
    );
  }
};

export default LandingPageEasyPeasy;
