import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import StyledText from "../../components/texts/StyledTextHalfColored";
import { Typography } from "@mui/material";
import LandingPageCard from "../../components/cards/LandingPageCard";
import MembershipCard from "../../components/cards/MembershipCard";
import "../../assets/fonts/fonts.css";

const LandingPageBloomplates = () => {
  return (
    <Container id="preturi" style={{ marginTop: 160 }}>
      <Row className="text-center">
        <StyledText
          color={"#DCF552"}
          fontSize={40}
          fontWeight={700}
          textNormal={"Ești gata să mănânci numai"}
          textUnderlined={"bloom-plates"}
        />
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col>
          <MembershipCard />
        </Col>
        <Col>
         
          <Row className="mt-5">
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: 300,
              marginBottom: 4,
            }}
          >
            Vei reuși să ai:
          </Typography>
            <LandingPageCard
              header={"Energie pe tot parcursul zilei"}
              content={
                "Cu mese echilibrate, vei avea energia necesară pe tot parcursul zilei"
              }
            />
            <LandingPageCard
              header={"Mai mult timp pentru tine"}
              content={
                "Fără stresul planificării zilnice, îți alegi cât de des vrei să gătești, având control total asupra timpului tău și a meselor sănătoase."
              }
            />
            <LandingPageCard
              header={"Plăcerea de a mânca fără griji"}
              content={
                "Savurezi mese delicioase, știind că sunt adaptate nevoilor și sănătății tale"
              }
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageBloomplates;
