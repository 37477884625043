import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const StyledButton = styled(Button)(({ marginleft, width }) => ({
  color: "#000",
  borderColor: "#000",
  fontFamily: "Poppins",
  fontSize: 16,
  fontWeight: 400,
  borderRadius: 10,
  textTransform: "none",
  padding: "8px 16px",
  marginLeft: marginleft || 0,
  width: width,
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#117233",
    backgroundColor: "#117233",
    boxShadow: "none",
    color: '#fff'
  },
}));

const ButtonForm = ({ text, marginLeft, className, width, onClick, style }) => {
  return (
    <StyledButton
      variant="outlined"
      size="medium"
      disableElevation
      marginleft={marginLeft}
      className={className}
      width={width}
      onClick={onClick}
      style={style}
    >
      {text}
    </StyledButton>
  );
};

ButtonForm.propTypes = {
  text: PropTypes.string.isRequired,
  marginLeft: PropTypes.string,
  className: PropTypes.string,
};

ButtonForm.defaultProps = {
  marginLeft: "0",
  className: "",
};

export default ButtonForm;
