import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Typography } from "@mui/material";
import { Box, styled, useMediaQuery } from "@mui/system";
import imagine from "./imagine.jpeg"; // Keep the background image if needed
import HomeNavbar from "../../components/navbar/HomeNavbar";
import Tick from "../../components/buttons/Tick";
import logo from './logoActual2.png';
import { useNavigate } from "react-router-dom";
// const Logo = styled(Typography)({
//   fontSize: "1.5rem",
//   fontWeight: "bold",
//   marginRight: "8px",
// });

const TermsOfUse = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate()
  return (
    <Container
      fluid
      style={{
        minWidth: "100%",
        padding: 0,
        overflowX: "hidden",
        backgroundColor: "#FFFDF2",
      }}
    >
      {isMobile && (
        <>
          <HomeNavbar color={"#0C3919"} />
          <hr style={{ height: 1, backgroundColor: "#000", border: "none" }} />
        </>
      )}

      <Row style={{ height: "100vh" }}>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className="align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                  <Image src={logo} width={"120"} onClick={() => navigate("/")}/>

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col
  style={{
    backgroundColor: "#FFFDF2",
    height: "100vh",
    display: "block",
    overflowY: "auto",
    padding: 20,
  }}
>
  <Typography
    sx={{
      fontFamily: "Poppins",
      fontSize: "40px",
      fontWeight: "bold",
      color: "#000",
    }}
  >
    Termeni și Condiții
  </Typography>
  
  <Row style={{ marginTop: "20px" }} className="mt-4">
    <Col xs={12}>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: "300",
          color: "#000",
        }}
      >
        Website-ul www.bloomplate.com este deținut de BLOOMPLATE S.R.L., cu următoarele informații de identificare: Codul Unic de Înregistrare (CUI): 50692242, înregistrată la Registrul Comerțului cu numărul J2024030635009.
        <br />
        Prin accesarea și utilizarea acestui site, sunteți de acord să respectați acești termeni și condiții, precum și să respectați toate legile și regulamentele aplicabile. În cazul în care nu sunteți de acord cu acești termeni și condiții, vă rugăm să nu folosiți site-ul.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        MODIFICAREA TERMENILOR ȘI CONDIȚIILOR
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Website-ul bloomplate.com își rezervă dreptul de a modifica termenii și condițiile fără a emite o notificare prealabilă. Vă recomandăm să verificați periodic această pagină pentru a lua cunoștință de eventualele modificări.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        PROTECȚIA PROPRIETĂȚII INTELECTUALE ȘI COPYRIGHT
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Toate materialele disponibile pe website-ul www.bloomplate.com, inclusiv dar fără a se limita la texte, imagini, logo-uri, grafice, software și alte materiale digitale, sunt protejate de legislația privind drepturile de autor și sunt proprietatea Bloomplate SRL. Fără aprobarea explicită și scrisă a Bloomplate SRL, utilizatorii sau terții nu au permisiunea să copieze, reproducă parțial sau integral, adapteze sau publice informații găsite pe website-ul bloomplate.com.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        CLAUZA DE CONFIDENȚIALITATE
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Începând cu data de 25 mai 2018, a intrat în vigoare și se aplică în toate statele membre ale Uniunii Europene Regulamentul (UE) nr. 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016, cunoscut și sub numele de GDPR (Regulamentul privind protecția datelor cu caracter personal). În calitate de Operator de date cu caracter personal, Bloomplate SRL angajează să respecte confidențialitatea datelor dvs. personale în conformitate cu legislația în vigoare și politica de confidențialitate.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        LIMITAREA RĂSPUNDERII
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Conținutul website-ului bloomplate.com poate include link-uri sau informații care nu se află sub controlul direct al BLOOMPLATE SRL. Prin urmare, BLOOMPLATE SRL nu își asumă responsabilitatea pentru conținutul acestor link-uri către terțe părți și nici pentru eventualele pierderi sau daune care ar putea rezulta din utilizarea acestora. De asemenea, BLOOMPLATE SRL nu oferă garanții privind funcționarea neîntreruptă sau fără erori a website-ului.
        <br />
        În ceea ce privește planurile alimentare, BLOOMPLATE SRL nu poate fi trasă la răspundere, în limitele impuse de lege, pentru complicații legate de alimentație, cum ar fi intoleranțele alimentare sau alergiile. Aceste planuri sunt destinate exclusiv scopului informativ și nu trebuie considerate un substitut pentru consultanța medicală personalizată.
        <br />
        Utilizatorul are obligația de a respecta recomandările furnizate de BLOOMPLATE SRL pentru a obține rezultatele dorite. Abaterea de la aceste recomandări poate influența rezultatul final, iar BLOOMPLATE SRL nu își asumă responsabilitatea pentru consecințele acestor abateri.
        <br />
        Valorile nutriționale incluse în materialele BLOOMPLATE SRL sunt aproximative și pot varia în funcție de tipul și calitatea ingredientelor utilizate, precum și de metodele de preparare. Deși facem toate eforturile pentru a oferi informații precise, recomandăm utilizatorilor care doresc o exactitate maximă să calculeze individual valorile nutriționale, luând în considerare specificul ingredientelor folosite.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        LITIGII
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Orice litigiu care poate să apară între utilizator și Bloomplate SRL va fi rezolvat prin mijloace amiabile. În caz contrar, litigiul va fi soluționat de către instanțele competente din România. Acești termeni și condiții reprezintă întregul acord între utilizator și site-ul în ceea ce privește utilizarea acestuia.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        REȚINEREA ADRESEI DE EMAIL
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Acceptând acești termeni și condiții, sunteți de acord să ne permiteți păstrarea adresei dvs. de email pentru viitoare comunicări legate de platforma noastră.
      </Typography>

      <Typography className="mt-4 mb-2" sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
        INFORMAȚII ADIȚIONALE
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "300", color: "#000" }}>
        Pentru mai multe întrebări vă rugăm să ne contactați la bloomplatesocial@gmail.com
      </Typography>
    </Col>
  </Row>
</Col>

      </Row>
    </Container>
  );
};

export default TermsOfUse;
