import React from "react";
import HomeNavbar from "../../components/navbar/HomeNavbar";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "./imagine.jpeg";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyledText from "../../components/texts/StyledTextHalfColored";
import { useNavigate } from "react-router-dom";

const LandingPageIntro = () => {
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100%",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 0,
        fontFamily: "Poppins",
        overflowX: "hidden",
        overflowY: "hidden",
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
      }}
    >
      <HomeNavbar />
      <hr style={{ height: 1, backgroundColor: "white", border: "none" }} />
      <Container style={{ marginTop: 80, marginBottom: 40 }}>
        <Row className="h-100 align-items-center">
          <Col className="text-white text-center">
            {isSmallScreen ? (
              <StyledText
                color={"#FF2C64"}
                fontSize={40}
                fontWeight={700}
                textNormal={"Mâncatul sănătos tocmai ce a devenit"}
                textUnderlined={"ireal de simplu"}
              />
            ) : (
              <StyledText
                color={"#FF2C64"}
                fontSize={80}
                fontWeight={700}
                textNormal={"Mâncatul sănătos tocmai ce a devenit"}
                textUnderlined={"ireal de simplu"}
              />
            )}

            <p style={{ fontWeight: 500 }} className="lead mt-4 mb-4 subtitle">
              Bloomplate este platforma care simplifică mâncatul sănătos <br/> ca tu
              să poți avea un stil de viață echilibrat.
            </p>
            <br />
          </Col>
          <Row className="align-items-center justify-content-center">
            <Col className="text-center">
              {isSmallScreen ? (
                <>
                  <ButtonPrimary
                    onClick={() => navigate("/questionaire/age")}
                    className="text-center"
                    text={"Start chestionar"}
                  />
                  <ButtonSecondary
                    href="#how"
                    className="mt-3 text-center"
                    text={"Află cum funcționează"}
                  />
                </>
              ) : (
                <>
                  <ButtonSecondary
                    href="#how"
                    className="m-2 text-center"
                    text={"Află cum funcționează"}
                  />
                  <ButtonPrimary
                    onClick={() => navigate("/questionaire/age")}
                    className="m-2 text-center"
                    text={"Start chestionar"}
                  />
                </>
              )}
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPageIntro;
