import { Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Nav,
  ListGroup,
  Card,
} from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RecipeCard from "../../components/cards/RecipeCard";
import "./DashboardSidebar.css";
import YogurtCard from "../../components/cards/YogurtCard";
import ShoppingListCard from "../../components/cards/ShoppingListCard";
import ResponsiveNavbar from "../../components/navbar/ResponsiveNavbar";
import AddRecipeCard from "../../components/cards/AddRecipeCard";

const DashboardDesktop = ({}) => {
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);

  return (
    <Container fluid style={{ backgroundColor: "#FFFCF1"}}>
      <Row>
        {/* Lateral Navigation */}
        <Col
          lg={2}
          // xs={2}
          // md={2}
          style={{
            backgroundColor: "#E7EFDF",
            height: "100vh",
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            position: "sticky", // Make it sticky
            top: 0, // Stick to the top
            overflowY: "auto", // Allow vertical scrolling if needed
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#0C3919",
            }}
          >
            bloomplate*
          </Typography>
          {/* <Nav className="d-flex flex-column"> */}

          <Button
            className="mt-5 p-3 mb-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isDashboardClicked ? "#117233" : "transparent",
              border: isDashboardClicked ? "none" : "1px solid #000",
              color: isDashboardClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsDashboardClicked(!isDashboardClicked)}
          >
            <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
          </Button>
          <Button
            className="mb-3 p-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isCumpClicked ? "#117233" : "transparent",
              border: isCumpClicked ? "none" : "1px solid #000",
              color: isCumpClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsCumpClicked(!isCumpClicked)}
          >
            <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
          </Button>
          <Button
            className="mb-3 p-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isProfilClicked ? "#117233" : "transparent",
              border: isProfilClicked ? "none" : "1px solid #000",
              color: isProfilClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsProfilClicked(!isProfilClicked)}
          >
            <PersonIcon sx={{ marginRight: "20px" }} /> Profil
          </Button>
          {/* </Nav> */}
          <Button
            className="mb-4 mt-auto p-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isLogOutClicked ? "#117233" : "transparent",
              border: isLogOutClicked ? "none" : "1px solid #000",
              color: isLogOutClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsLogOutClicked(!isLogOutClicked)}
          >
            <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
          </Button>
        </Col>

        <Col lg={10} className="p-4">
          <Typography
            sx={{ fontFamily: "Poppins", fontSize: "30px", fontWeight: "bold" }}
          >
            Dashboard
          </Typography>
          <Row>
            <Col style={{ border: "1px solid #fff" }} lg={8}>
              <div
                className="mt-4 mb-2 ml-2 mr-2"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  justifyContent: "space-between",
                  display: "flex",
                  marginLeft: "10px",
                  marginRight: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px"
                }}
              >
                <span>LUNI</span>
                <span>MARTI</span>
                <span>MIERCURI</span>
                <span>JOI</span>
                <span>VINERI</span>
                <span>SAMBATA</span>
                <span>DUMINICA</span>
              </div>
              <div
                style={{
                  backgroundColor: "#F3A7BB",
                  borderRadius: "60px",
                  padding: "12px",
                  paddingRight: "20px",
                  paddingLeft: "20px",

                  display: "flex",
                  fontFamily: "Poppins",
                  justifyContent: "space-between",
                }}
              >
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
              </div>
              <Typography
                className="mt-4 mb-2"
                sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Mic Dejun
              </Typography>
              <RecipeCard isDesktop={true}/>
              <Typography
                className="mt-4 mb-2"
                sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Pranz
              </Typography>
              <RecipeCard isDesktop={true}/>
              <Typography
                className="mt-4 mb-2"
                sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Cina
              </Typography>
              <RecipeCard isDesktop={true}/>
              <Typography
                className="mt-4 mb-2"
                sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
              >
                Snack
              </Typography>
              <AddRecipeCard />
            </Col>
            <Col lg={4} style={{marginTop: 60}}>
              <YogurtCard />
              <ShoppingListCard />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardDesktop;
