// Import necessary modules and libraries
import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

// Create an AuthContext to be used throughout the app
export const AuthContext = createContext();

// AuthProvider component to provide authentication context to its children
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  // State variables to manage authentication status, user role, and loading state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Logout function to clear the authentication token and reset user state
  const logout = useCallback(() => {
    // axios.post("https://bloomplate-backend-990735649563.herokuapp.com/auth/logout", null, {
    //   headers: {
    //     Authorization: `Token ${Cookies.get("authToken")}`,
    //   },
    // }); // Send a POST request to the server to log out the user
    Cookies.remove("authToken"); // Remove the authentication token from cookies
    sessionStorage.clear();
    setIsAuthenticated(false); // Set authentication status to false
    navigate("/landing-page"); // Navigate to the landing page
  }, [navigate]);

  // useEffect to check for the authentication token on component mount
  useEffect(() => {
    const token = Cookies.get("authToken");
    if (token) {
      console.log("aici intra")
      setIsAuthenticated(true); // Set authentication status to true
    }
      setIsLoading(false); // Set loading state to false if no token is found
    
  }, []);

  // Login function to set the authentication token and fetch user role
  const login = (token) => {
    Cookies.set("authToken", token); // Set the authentication token in cookies
    setIsAuthenticated(true); // Set authentication status to true
    setIsLoading(false); // Set loading state to true while fetching user role
    navigate("/");
  };

  // Provide the authentication context to children components
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
