import React from "react";
import { Card, Stack } from "react-bootstrap";
import "./TestimonialCard.css";
import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@mui/material";

const TestimonialCard = ({ noStars, header, content, name }) => {
  return (
    <Card
      className="card-testimonial"
      style={{ fontFamily: "Poppins", borderRadius: "20px", backgroundColor: '#0c3919', minHeight: "500px" }}
    >
      <Stack direction="horizontal" className="mt-3">
        {Array.from({ length: noStars }, (_, index) => (
          <StarIcon style={{ color: "#DCF552" }} key={index} />
        ))}
      </Stack>
      <Typography
        className="mt-2"
        style={{ color: "#fff", fontFamily: "Poppins", fontWeight: "bold" }}
      >
        {header}
      </Typography>
      <Typography
        className="mt-3"
        style={{ color: "#fff", fontFamily: "Poppins", fontStyle: "italic" }}
      >
        "{content}"
      </Typography>
      <Typography
        className="mt-3"
        style={{ color: "#fff", fontFamily: "Poppins", fontWeight: "bold" }}
      >
        {name}
      </Typography>
    </Card>
  );
};

export default TestimonialCard;
