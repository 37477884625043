import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SkeletonYogurtCard from "./SkeletonYogurtCard";

const YogurtCard = ({ recommendations }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // Function to handle image change by index
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === recommendations.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? recommendations.length - 1 : prevIndex - 1
    );
  };

  if (recommendations) {
    return (
      <Card
        className="text-center shadow-sm"
        style={{
          width: "100%",
          height: "400px",
          borderRadius: "15px",
          border: "none",
          overflow: "hidden",
          boxShadow: "0 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow
        }}
      >
        <Card.Body
          style={{
            position: "relative",
            padding: "20px",
            backgroundImage: recommendations[currentIndex]?.thumbnail
              ? `url(${recommendations[currentIndex].thumbnail})`
              : "url(default-image.jpg)", // Provide a fallback image or color
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            overflow: "hidden", // To contain overlay
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent overlay
              borderRadius: "15px",
              zIndex: 1,
            }}
          ></div>

          <div className="d-flex justify-content-center mb-4" style={{ zIndex: 2 }}>
            {recommendations.map((_, index) => (
              <div
                key={index}
                onClick={() => handleDotClick(index)}
                style={{
                  width: "80px",
                  height: "8px",
                  backgroundColor: index === currentIndex ? "#FF2C64" : "#666561",
                  borderRadius: "5px",
                  margin: "0 10px",
                  cursor: "pointer",
                  zIndex: 2, // Ensure button is above the overlay
                }}
              ></div>
            ))}
          </div>

          {/* Card Title positioned over the overlay */}
          <Card.Title
            className="text-white"
            style={{
              fontSize: "18px",
              marginBottom: "1rem",
              fontFamily: "Poppins",
              zIndex: 2, // Ensure it is above the overlay
              position: "relative", // Allow positioning on top of overlay
            }}
          >
            {recommendations[currentIndex]?.title || ""}
          </Card.Title>

          <Button
            className="px-4 py-2 mt-auto"
            style={{
              borderRadius: "10px",
              position: "absolute",
              bottom: "20px",
              left: "20px",
              fontFamily: "Poppins",
              backgroundColor: "#FF2C64",
              border: "none",
              zIndex: 2, // Ensure button is above the overlay
            }}
            onClick={() => navigate(`/dashboard/recomandare/${recommendations[currentIndex].id}`)}
          >
            Află mai mult
          </Button>

          {/* Next and Previous buttons with chevron icons */}
          <Button
            onClick={prevImage}
            style={{
              position: "absolute",
              top: "50%",
              left: "2px",
              zIndex: 2,
              borderRadius: "50%",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              color: "#fff",
            }}
          >
            <ChevronLeft fontSize="large" color="#fff"/>
          </Button>

          <Button
            onClick={nextImage}
            style={{
              position: "absolute",
              top: "50%",
              right: "2px",
              zIndex: 2,
              borderRadius: "50%",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              color: "#fff",
            }}
          >
            <ChevronRight fontSize="large" color="#fff" />
          </Button>
        </Card.Body>
      </Card>
    );
  } else {
    return <SkeletonYogurtCard/>;
  }
};

export default YogurtCard;
