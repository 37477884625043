import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import LandingPageCardSecondary from "../../components/cards/LandingPageCardSecondary";
import "../../assets/fonts/fonts.css";
import poza11 from './poza11.png'
import poza12 from './poza12.png'
import poza13 from './poza13.png'

const LandingPageBenefits = () => {
  return (
    <Container style={{ marginTop: 160 }}>
      <Row>
        <Col>
          <Typography
            sx={{
              fontFamily: "National2Bold",
              fontWeight: 700,
              color: "#000",
              fontSize: 40,
            }}
          >
            Serios, chiar poate fi atât de simplu:
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col xs={12} lg={6} md={6}>
          <Row>
            <Col xs={12} lg={10}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: "#FF2C64",
                  fontSize: 12,
                }}
              >
                SALVEZI TIMP SI BANI
              </Typography>
              <Typography
                className="mt-3 mb-3"
                sx={{ fontWeight: "bold", fontFamily: "Poppins", fontSize: 26 }}
              >
                Graba nu îți mai lasă timp în farfurie pentru mese sănătoase?
              </Typography>
              <Typography
                className="mb-3"
                sx={{ fontFamily: "Poppins", fontSize: 18, fontWeight: 300 }}
              >
                Un plan pregătit din timp îți permite să economisești ore
                prețioase și bani în fiecare săptămână, ca tu să te concentrezi
                pe ce contează cu adevărat.{" "}
              </Typography>
              <Row style={{ marginTop: 40 }}>
                <Col>
                  <LandingPageCardSecondary content={"Planuri gata făcute"} />
                  <LandingPageCardSecondary
                    content={"Ingrediente livrate acasă"}
                  />
                </Col>
                <Col>
                  <LandingPageCardSecondary
                    content={"Listă de cumpărături automată"}
                  />
                  <LandingPageCardSecondary
                    content={"Eviți risipa alimentară"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6} md={6}>
          <Image
            src={poza11}
            width={"100%"}
            height={"100%"}
            rounded
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 80 }}>
      <Col xs={{ order: 2, span: 12 }} lg={{order: 1, span: 6}} md={6}>
          <Image
            src={poza12}
            width={"100%"}
            height={"100%"}
            rounded
          />
        </Col>
        <Col xs={{ order: 1, span: 12 }} lg={{order: 2, span: 6}} md={6}>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 700,
              color: "#FF2C64",
              fontSize: 12,
            }}
          >
            REȚETE PE GUSTUL TĂU
          </Typography>
          <Typography
            className="mt-3 mb-3"
            sx={{ fontWeight: "bold", fontFamily: "Poppins", fontSize: 26 }}
          >
            Rețete delicioase sau sănătoase? O întrebare pe care nu o să ți-o
            mai pui.{" "}
          </Typography>
          <Typography
            className="mb-3"
            sx={{ fontFamily: "Poppins", fontSize: 18, fontWeight: 300 }}
          >
            Da, mănânci ce îți place, fără sute de compromisuri. Primești rețete
            personalizate care îți respectă gusturile și îți susțin sănătatea.
            Fie că vrei ceva fresh sau consistent, e totul gândit pentru tine.{" "}
          </Typography>

          <Row style={{ marginTop: 40 }}>
            <Col>
              <LandingPageCardSecondary
                content={"Rețete delicioase, dar și corecte nutrițional"}
              />
              <LandingPageCardSecondary content={"Fără stresul planificării"} />
            </Col>
            <Col>
              <LandingPageCardSecondary
                content={"Instrucțiuni simple pentru gătit rapid"}
              />
              <LandingPageCardSecondary
                content={"Totul personalizat pe gustul tău"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 80 }}>
        <Col xs={12} lg={6} md={6}>
          <Row>
            <Col xs={12} lg={10}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: "#FF2C64",
                  fontSize: 12,
                }}
              >
                MĂNÂNCI SĂNĂTOS
              </Typography>
              <Typography
                className="mt-3 mb-3"
                sx={{ fontWeight: "bold", fontFamily: "Poppins", fontSize: 26 }}
              >
                “Îmi este poftă, dar nu e așa sănătos.”
              </Typography>
              <Typography
                className="mb-3"
                sx={{ fontFamily: "Poppins", fontSize: 18, fontWeight: 300 }}
              >
                Știm cum e – poftele sunt greu de ignorat, dar nici nu mai
                trebuie. Îți oferim rețete sănătoase care țin cont de simptomele
                și afecțiunile tale, ca să mănânci cu poftă și fără griji.{" "}
              </Typography>

              <Row style={{ marginTop: 40 }}>
                <Col>
                  <LandingPageCardSecondary
                    content={"Rețete aprobate de nutriționiști"}
                  />
                  <LandingPageCardSecondary
                    content={"Bazate pe nevoile tale de sănătate"}
                  />
                </Col>
                <Col>
                  <LandingPageCardSecondary
                    content={"Gândite să amelioreze simptome "}
                  />
                  <LandingPageCardSecondary
                    content={"Concepute pentru prevenție"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6} md={6}>
          <Image
            src={poza13}
            width={"100%"}
            height={"100%"}
            rounded
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageBenefits;
