import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Stack,
  Spinner,
  Image,
} from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import imagine from "./imagine.jpeg";
import { Alert, Divider, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import Tick from "../../components/buttons/Tick";
import ButtonForm from "../../components/buttons/ButtonForm";
import Back from "../../components/buttons/Back";
import BoxNumber from "../../components/buttons/BoxNumber";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import BoxNumberOutlined from "../../components/buttons/BoxNumberOutlined";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import RegisterState from "../../context/RegisterState";
import { AuthContext } from "../../context/AuthContext";
import logo from "./logoActual2.png";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const QuestionaireRegister = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const registerState = useContext(RegisterState);
  const [username, setUsername] = useState(registerState.username);
  const [email, setEmail] = useState(registerState.email);
  const [phone, setPhone] = useState(registerState.phoneNumber);
  const [password, setPassword] = useState(registerState.password);
  const [errorMessage, setErrorMessage] = useState(null);

  let user = {};
  const { login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
  });

  const validateForm = async () => {
    const newErrors = {
      username: "",
      email: "",
      phone: "",
      password: "",
    };

    let isValid = true;

    // Validate username
    if (!username.trim()) {
      newErrors.username = "Numele de utilizator este obligatoriu.";
      isValid = false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      newErrors.email = "E-mailul este obligatoriu.";
      isValid = false;
    } else if (!emailRegex.test(email)) {
      newErrors.email = "E-mailul nu este valid.";
      isValid = false;
    }

    // Validate phone
    if (!phone.trim()) {
      newErrors.phone = "Numărul de telefon este obligatoriu.";
      isValid = false;
    }

    // Validate password
    if (!password) {
      newErrors.password = "Parola este obligatorie.";
      isValid = false;
    } else if (password.length < 8) {
      // Minimum length is 8
      newErrors.password = "Parola trebuie să aibă cel puțin 8 caractere.";
      isValid = false;
    } else if (!/[0-9]/.test(password)) {
      // Check for numeric character
      newErrors.password =
        "Parola trebuie să conțină cel puțin un caracter numeric.";
      isValid = false;
    } else if (!/[A-Z]/.test(password)) {
      // Check for uppercase letter
      newErrors.password = "Parola trebuie să conțină cel puțin o literă mare.";
      isValid = false;
    } else if (!/[a-z]/.test(password)) {
      // Check for lowercase letter
      newErrors.password = "Parola trebuie să conțină cel puțin o literă mică.";
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      // Check for special character
      newErrors.password =
        "Parola trebuie să conțină cel puțin un caracter special.";
      isValid = false;
    }

    setErrors(newErrors);

    // If validation failed, return false
    if (!isValid) {
      return false;
    }

    const data = {
      username: username,
      email: email,
    };
    setErrorMessage(null);

    try {
      setLoading(true);
      const response = await axios.post(
        "https://bloomplate-backend-990735649563.herokuapp.com/api/user/exists/",
        data
      );


      // Check if the user exists and handle errors accordingly
      const { exists, messages } = response.data;

      if (exists) {
        const newErrors = {};

        // Check for specific error messages and set them
        if (messages.username) {
          newErrors.username =
            "Există deja un cont cu acest nume de utilizator."; // Username already exists
        }
        if (messages.email) {
          newErrors.email = "Există deja un cont cu acest email."; // Email already exists
        }

        setErrors(newErrors);
        setLoading(false);
        return false; // User already exists, prevent further execution
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "A apărut o eroare la autentificare."
      );
      return false; // Handle errors in the Axios request
    }

    return true; // Validation and existence check passed
  };

  const handleSubmit = async () => {
    // Wait for validation and user existence check
    const isValid = await validateForm();
    if (!isValid) {
      return; // Stop execution if validation or user check failed
    }

    // Set user data after validation
    registerState.setUsername(username);
    registerState.setPassword(password);
    registerState.setEmail(email);
    registerState.setPhoneNumber(phone);

    const user = {
      username,
      password,
      email,
      fullname: username,
      phone_number: phone,
      age: registerState.age,
      gender: registerState.gender,
      alimentation_type: registerState.alimentationType,
      energy: registerState.energy,
      stress: registerState.stress,
      pcos: registerState.pcos,
      endomethriosis: registerState.endomethriosis,
      bloatingFree: registerState.bloatingFree,
      constipationFree: registerState.constipationFree,
      lactoseFree: registerState.lactoseFree,
      glutenFree: registerState.glutenFree,
      foods_to_be_excluded: "aia zic",
      time: registerState.time,
      meal_preference: registerState.mealPreference,
      kcal: registerState.kcal,
      plan: registerState.plan,
    };

    const productIndex = registerState.plan - 1;

    // registerUser(user);
    navigate("/payment", { state: { user, productIndex } });
  };


  return (
    <Container
      fluid
      style={{
        minWidth: "100%",
        padding: 0,
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Row>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              overflowY: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className="align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                  <Image
                    src={logo}
                    width={"120"}
                    onClick={() => navigate("/")}
                  />

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col lg={8} xs={12} style={{ backgroundColor: "#FFFDF2" }}>
          <Row
            style={{
              padding: 20,
              marginBottom: "80px",
              marginLeft: !isMobile ? 80 : 0,
              marginTop: 40,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Creează contul gratuit, cu acces complet pentru 1 săptămână.
            </Typography>
            <Row className="mt-4">
              <Col lg={5}>
                <Form.Label
                  for="name"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  User Name
                </Form.Label>
                <InputGroup className=" mb-3">
                  <Form.Control
                    id="name"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="text"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </InputGroup>

                <Form.Label
                  for="email"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Email
                </Form.Label>
                <InputGroup className=" mb-3">
                  <Form.Control
                    id="email"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="email"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="nume@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Label
                  for="phone"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Telefon
                </Form.Label>
                <InputGroup className=" mb-3">
                  <Form.Control
                    id="phone"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="text"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="+40729565538"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Label
                  for="password"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Parolă
                </Form.Label>
                <InputGroup className=" mb-3">
                  <Form.Control
                    id="password"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="password"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="avocado123"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
                <Typography
                  className="mt-2"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "italic",
                    color: "#D3D3D3",
                  }}
                >
                  Folosind Bloomplate acceptați Termenii și Condițiile
                </Typography>
                <ButtonPrimary
                  className="mt-3"
                  onClick={() => handleSubmit()}
                  text={
                    loading ? (
                      <Spinner animation="border" />
                    ) : (
                      "Înregistrează-te"
                    )
                  }
                />
                {errorMessage && (
                  <Alert
                    style={{ fontFamily: "Poppins" }}
                    variant="danger"
                    className="mt-2"
                  >
                    {errorMessage}
                  </Alert>
                )}
              </Col>
              <Col className="mt-5" lg={7}>
                <Row>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "#000",
                    }}
                  >
                    Pentru timp de 1 săptămână te vei bucura gratis de:{" "}
                  </Typography>
                  <Stack className="mt-4" direction="horizontal">
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#DCF552",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // margin: "auto",
                        marginRight: 4,
                      }}
                    >
                      <CheckIcon sx={{ color: "#000" }} />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      Eviți timpul de la cumpărături
                    </Typography>
                  </Stack>
                  <Stack className="mt-4" direction="horizontal">
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#DCF552",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // margin: "auto",
                        marginRight: 4,
                      }}
                    >
                      <CheckIcon sx={{ color: "#000" }} />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      Nu mai petreci ore întregi în bucătărie
                    </Typography>
                  </Stack>
                  <Stack className="mt-4" direction="horizontal">
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#DCF552",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // margin: "auto",
                        marginRight: 4,
                      }}
                    >
                      <CheckIcon sx={{ color: "#000" }} />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      Planuri de mese gata făcute{" "}
                    </Typography>
                  </Stack>
                  <Stack className="mt-4" direction="horizontal">
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#DCF552",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // margin: "auto",
                        marginRight: 4,
                      }}
                    >
                      <CheckIcon sx={{ color: "#000" }} />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      Inspirație constantă{" "}
                    </Typography>
                  </Stack>
                </Row>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default QuestionaireRegister;
