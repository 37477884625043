import React from "react";
import LandingPageHow from "./LandingPageHow";
import LandingPageBenefits from "./LandingPageBenefits";
import LandingPageBloomplates from "./LandingPageBloomplates";
import LandingPageDream from "./LandingPageDream";
import LandingPageEasyPeasy from "./LandingPageEasyPeasy";
import LandingPageHomework from "./LandingPageHomework";
import LandingPageIntro from "./LandingPageIntro";
import LandingPageTable from "./LandingPageTable";
import LandingPageTestimonials from "./LandingPageTestimonials";
import LandingPageFAQs from "./LandingPageFAQs";
import HomeFooter from "../../components/navbar/HomeFooter";
import CookieConsent from "react-cookie-consent";

const LandingPage = ({}) => {
  return (
    <div style={{ backgroundColor: "#FFFEF7" }}>
      <LandingPageIntro /> {/* it's done */}
      <LandingPageHow /> {/* it's done */}
      <LandingPageEasyPeasy />
      <LandingPageHomework />
      <LandingPageBenefits />
      <LandingPageDream />
      <LandingPageTable />
      <LandingPageTestimonials />
      <LandingPageBloomplates />
      <LandingPageFAQs />
      <HomeFooter />
      <CookieConsent
        location="bottom"
        buttonText="Accept cookie-urile!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Acest site folosește cookies pentru a-ți oferi o experiență
        personalizată și pentru a îmbunătăți performanța. Cookies ne ajută să
        înțelegem mai bine preferințele tale și să adaptăm conținutul și
        reclamele în funcție de acestea. Continuând să navighezi pe site, ești
        de acord cu utilizarea cookies. Pentru mai multe informații, poți
        consulta Politica noastră de cookies.{" "}
      </CookieConsent>
    </div>
  );
};

export default LandingPage;
