import { Box } from "@mui/system";
import React from "react";
import { Card, Stack, Col, Row } from "react-bootstrap";
import { FaClock, FaLayerGroup } from "react-icons/fa";
import ReplayIcon from "@mui/icons-material/Replay";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const RecipeCard = ({
  isDesktop,
  text,
  description,
  time,
  noIngredients,
  image,
  mealId,
  day,
  meal_type,
  handleDeleteClick,
  dontTruncate = false,
  influencer,
}) => {
  const navigate = useNavigate();

  const handleReplayClick = (event) => {
    event.stopPropagation(); // Prevents triggering the card's onClick
    navigate("/dashboard/meniu-mese", { state: { mealId, day, meal_type } }); // Pass mealId as state
  };

  function truncateDescription(description) {
    const maxLength = 100;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  function truncateDescription2(description) {
    const maxLength = 20;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  return (
    <Card
      onClick={() => navigate(`/dashboard/pagina-mancare/${mealId}`)}
      className="rounded-3 mb-2"
      style={{
        backgroundColor: "#FFFEF7",
        fontFamily: "Poppins",
        border: "none",
        boxShadow: "5px 5px 5px 5px #f0f0f0",
        height: "auto",
      }}
    >
      <Row className="g-0">
        <Col xs={6} md={4} className="p-0">
          {" "}
          {/* Removed d-flex to allow image to fill space */}
          <Card.Img
            variant="left"
            src={image}
            style={{
              width: "100%",
              height: "auto",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",

            }}
          />
        </Col>
        <Col
          xs={6}
          md={8}
          className="d-flex flex-column justify-content-between"
        >
          <Card.Body className="p-3 d-flex flex-column">
            <div>
              {!isDesktop && (
                <Card.Title className="fs-6" style={{ fontWeight: "bold", fontSize: "10px" }}>
                  {truncateDescription2(text)}
                  {/* {text} */}
                </Card.Title>
              )}
              {isDesktop && (
                <Card.Title className="fs-6" style={{ fontWeight: "bold" }}>
                  {text}
                </Card.Title>
              )}
              {isDesktop && dontTruncate === false && (
                <Card.Text style={{ fontSize: "14px" }}>
                  {truncateDescription(description)}
                </Card.Text>
              )}
              {isDesktop && dontTruncate === true && (
                <>
                  <Card.Text style={{ fontSize: "14px" }}>
                    {description}
                  </Card.Text>
                  <Card.Text style={{ fontSize: "14px" }}>
                    {influencer}
                  </Card.Text>
                </>
              )}
            </div>
            <Stack gap={2} direction="horizontal" className="mt-auto">
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaClock size={12} />
                <span className="ms-1 fs-7" style={{ fontSize: 12 }}>
                  {time}m
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaLayerGroup size={12} />
                <span className="ms-1 fs-7" style={{ fontSize: 12 }}>
                  {noIngredients}
                </span>
              </div>

              {isDesktop && (
                <>
                  <Box
                    className="ms-auto"
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                  >
                    <DeleteIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteClick();
                      }}
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleReplayClick} // Added onClick for ReplayIcon
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                  >
                    <ReplayIcon
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                </>
              )}

              {!isDesktop && (
                <>
                  <Box
                    className="ms-auto"
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                  >
                    <DeleteIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteClick();
                      }}
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleReplayClick} // Added onClick for ReplayIcon
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                  >
                    <ReplayIcon
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                </>
              )}
            </Stack>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default RecipeCard;
