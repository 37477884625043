import { FaClock, FaUserAlt, FaLayerGroup } from "react-icons/fa";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, useMediaQuery } from "@mui/system";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import backgroundImage from "./image.png";
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";

import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Card,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import { Avatar, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RecipeCardBig from "../../components/cards/RecipeCardBig";
import RecipeCard from "../../components/cards/RecipeCard";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext";
const FoodPage = ({}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const { id } = useParams();
  const { logout } = useContext(AuthContext);

  const handleRemoveClick = () => {
    // Decrement servings only if it's greater than 0
    if (servings > 1) {
      setServings(servings - 1);
    }
    console.log("aia zic");
  };

  function calculateMacronutrientPercentages(kcal, fats, carbs, proteins) {
    // Calculate calories from each macronutrient
    const caloriesFromFats = fats * 9; // 9 kcal per gram of fat
    const caloriesFromCarbs = carbs * 4; // 4 kcal per gram of carbohydrate
    const caloriesFromProteins = proteins * 4; // 4 kcal per gram of protein

    // Calculate total calories from all macronutrients
    const totalCalories =
      caloriesFromFats + caloriesFromCarbs + caloriesFromProteins;

    // Calculate initial percentages
    let fatPercentage = (caloriesFromFats / totalCalories) * 100;
    let carbPercentage = (caloriesFromCarbs / totalCalories) * 100;
    let proteinPercentage = (caloriesFromProteins / totalCalories) * 100;

    // Round percentages
    fatPercentage = Math.round(fatPercentage);
    carbPercentage = Math.round(carbPercentage);
    proteinPercentage = Math.round(proteinPercentage);

    // Calculate the total of the rounded percentages
    const totalPercentage = fatPercentage + carbPercentage + proteinPercentage;

    // Adjust percentages to ensure they add up to 100%
    if (totalPercentage !== 100) {
      // Find the difference needed to reach 100
      const adjustment = 100 - totalPercentage;

      // Distribute the adjustment
      if (adjustment > 0) {
        // Increase the largest percentage to reach 100
        if (
          fatPercentage >= carbPercentage &&
          fatPercentage >= proteinPercentage
        ) {
          fatPercentage += adjustment;
        } else if (
          carbPercentage >= fatPercentage &&
          carbPercentage >= proteinPercentage
        ) {
          carbPercentage += adjustment;
        } else {
          proteinPercentage += adjustment;
        }
      } else {
        // Decrease the largest percentage to reach 100
        const absAdjustment = Math.abs(adjustment);
        if (
          fatPercentage >= carbPercentage &&
          fatPercentage >= proteinPercentage
        ) {
          fatPercentage -= absAdjustment;
        } else if (
          carbPercentage >= fatPercentage &&
          carbPercentage >= proteinPercentage
        ) {
          carbPercentage -= absAdjustment;
        } else {
          proteinPercentage -= absAdjustment;
        }
      }
    }

    return {
      fatPercentage,
      carbPercentage,
      proteinPercentage,
    };
  }

  const handleAddClick = () => {
    setServings(servings + 1);
  };

  const [selectedOption, setSelectedOption] = useState("ingrediente");
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [influencer, setInfluencer] = useState("");
  const [servings, setServings] = useState(0);
  const [time, setTime] = useState(0);
  const [noIngredients, setNoIngredients] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const [calories, setCalories] = useState(0);
  const [fibers, setFibers] = useState(0);
  const [proteins, setProteins] = useState(0);
  const [fats, setFats] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [instructions, setInstructions] = useState([]);
  const [image, setImage] = useState("");
  const [ingredientNames, setIngredientNames] = useState([]);
  const [amountUnits, setAmountUnits] = useState([]);
  const [username, setUsername] = useState("");
  const [ingredientsData, setIngredientsData] = useState([]);

  const token = Cookies.get("authToken");

  // Handler for button click
  const handleClick = (value) => {
    setSelectedOption(value);
  };

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://bloomplate-backend-990735649563.herokuapp.com/meals/${id}/`,
  //       {
  //         headers: {
  //           Authorization: `JWT ` + token,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       const data = response.data;

  //       // console.log(response.data)
  //       console.log(response.data.instructions);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching meals:", error);
  //     });

  //   axios
  //     .get(
  //       "https://bloomplate-backend-990735649563.herokuapp.com/auth/current-user",
  //       {
  //         headers: {
  //           Authorization: `JWT ${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       const data = response.data;
  //       setUsername(data.username);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [id]);

  // useEffect(() => {
  //   if (ingredients.length > 0) {
  //     const data = {
  //       ingredient_ids: ingredients,
  //       meal_id: id,
  //     };

  //     axios
  //       .post(
  //         "https://bloomplate-backend-990735649563.herokuapp.com/meals/ingredient-measurements/",
  //         data
  //       )
  //       .then((response) => {
  //         const ingredientsData = response.data;
  //         setIngredientNames(
  //           ingredientsData.map((item) => item.ingredient_name)
  //         );

  //         setAmountUnits(
  //           ingredientsData.map(
  //             (item) => `${item.amount * servings} ${item.unit}`
  //           )
  //         );
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, [ingredients, id, servings]); // Add id to dependency array if it affects the post request

  useEffect(() => {
    const fetchMealDetails = async () => {
      const mealQuery = `
        query {
          mealDetail(id: ${id}) {
            id
            title
            summary
            servings
            imageUrl
            instructions
            carbohydrates
            fats
            fibers
            proteins
            kcal
            readyInMinutes
            influencer
            ingredientMeasurements {
              id
              ingredient {
                id
                name
                normalizedName
              }
              amount
              unit
            }
            kcal
            readyInMinutes
          }
          username
        }
      `;

      try {
        const response = await axios.post(
          "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
          { query: mealQuery },
          {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
        );


        const mealData = response.data.data.mealDetail;
        const fetchedUsername = response.data.data.username;
        const ingredientsData = response.data.data.mealDetail.ingredientMeasurements
        setIngredientsData(ingredientsData);
        setTitle(mealData.title);
        setSummary(mealData.summary);
        setServings(mealData.servings);
        setTime(mealData.readyInMinutes);
        setNoIngredients(mealData.ingredientMeasurements.length);
        setIngredients(mealData.ingredients);
        setCalories(mealData.kcal);
        setCarbs(mealData.carbohydrates);
        setFats(mealData.fats);
        setFibers(mealData.fibers);
        setProteins(mealData.proteins);
        setInfluencer(mealData.influencer);
        setInstructions(
          mealData.instructions.split("\r\n\r\n").map((step) => step.trim())
        );
        setImage(mealData.imageUrl);
        setUsername(fetchedUsername);


        setIngredientNames(ingredientsData.map((item) => item.ingredient.name));

       
      } catch (error) {
        console.error("Error fetching meal details and username:", error);
      }
    };

    fetchMealDetails();
  }, [id, token]);

  useEffect(() => {
    setAmountUnits(
      ingredientsData.map(
        (item) => `${item.amount * servings} ${item.unit}`
      )
    );
  }, [ingredientsData, servings]);

  // Render text based on selected button
  const renderText = () => {
    switch (selectedOption) {
      case "ingrediente":
        return (
          <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 40 }}>
            <Button
              className="mb-5"
              variant="outline-dark"
              style={{
                borderRadius: "30px", // Rounded corners
                border: "transparent", // Black border
                backgroundColor: "#E7EFDF",
                color: "black", // Black text
                padding: "8px 16px",
                fontSize: "14px",
                width: "100%", // Full-width button
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px", // Space between icons and text
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  border: "transparent",
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                <RemoveIcon
                  style={{ color: "#FF2C64" }}
                  onClick={() => handleRemoveClick()}
                />
              </div>
              <span style={{ fontFamily: "Poppins" }}>{servings} portie</span>
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  border: "transparent",
                  backgroundColor: "#fff",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                <AddIcon
                  style={{ color: "#FF2C64" }}
                  onClick={() => handleAddClick()}
                />
              </div>
            </Button>
            {ingredientNames.map((name, index) => (
              <React.Fragment key={index}>
                <Stack direction="horizontal" gap={3}>
                  <Form.Check
                    style={{ borderColor: "#FF2C64" }}
                    type="checkbox"
                  />
                  <span style={{ fontFamily: "Poppins" }}>{name}</span>
                  <span className="ms-auto">{amountUnits[index]}</span>
                </Stack>
                <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                />
              </React.Fragment>
            ))}{" "}
            <Row className="mt-4">
              <ButtonPrimary text="Adaugă la cumpărături" />
            </Row>
          </div>
        );
      case "pregatire":
        return (
          <div style={{ paddingBottom: 40 }}>
            {instructions.map((instruction, index) => (
              <Row
                className="mt-4 mb-4"
                key={index}
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                <Col
                  className="p-3"
                  style={{
                    border: "1px solid #CFCFCF",
                    borderRadius: "10px",
                  }}
                >
                  <Stack direction="horizontal" gap={2}>
                    <Typography
                      style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      {`Pasul ${index + 1}`} {/* Display step number */}
                    </Typography>
                    <Form.Check type="checkbox" className="ms-auto" />
                  </Stack>
                  <Typography
                    className="mt-2"
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  >
                    {instruction} {/* Render the instruction */}
                  </Typography>
                </Col>
              </Row>
            ))}
          </div>
        );
      case "nutritie":
        return (
          <div
            className="text-center"
            style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 40 }}
          >
            <Stack
              direction="horizontal"
              gap={5}
              style={{ justifyContent: "center" }}
            >
              <div className="text-center" style={{ width: 80, height: 80 }}>
                <CircularProgressbar
                  styles={{
                    path: {
                      stroke: "#DC8826",
                    },
                    text: {
                      // Text color
                      fill: "#000",
                      // Text size
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    },
                  }}
                  value={
                    calculateMacronutrientPercentages(
                      calories,
                      fats,
                      carbs,
                      proteins
                    ).carbPercentage
                  }
                  maxValue={100}
                  text={
                    calculateMacronutrientPercentages(
                      calories,
                      fats,
                      carbs,
                      proteins
                    ).carbPercentage + "%"
                  }
                />
                <Typography
                  className="mt-2"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Carbs
                </Typography>
              </div>
              <div className="text-center" style={{ width: 80, height: 80 }}>
                <CircularProgressbar
                  styles={{
                    path: {
                      stroke: "#DC26B4",
                    },
                    text: {
                      // Text color
                      fill: "#000",
                      // Text size
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    },
                  }}
                  value={
                    calculateMacronutrientPercentages(
                      calories,
                      fats,
                      carbs,
                      proteins
                    ).proteinPercentage
                  }
                  text={
                    calculateMacronutrientPercentages(
                      calories,
                      fats,
                      carbs,
                      proteins
                    ).proteinPercentage + "%"
                  }
                  maxValue={100}
                />
                <Typography
                  className="mt-2"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Proteină
                </Typography>
              </div>
              <div className="text-center" style={{ width: 80, height: 80 }}>
                <CircularProgressbar
                  styles={{
                    path: {
                      stroke: "#26A5DC",
                    },
                    text: {
                      // Text color
                      fill: "#000",
                      // Text size
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    },
                  }}
                  maxValue={100}
                  value={
                    calculateMacronutrientPercentages(
                      calories,
                      fats,
                      carbs,
                      proteins
                    ).fatPercentage
                  }
                  text={
                    calculateMacronutrientPercentages(
                      calories,
                      fats,
                      carbs,
                      proteins
                    ).fatPercentage + "%"
                  }
                />

                <Typography
                  className="mt-2"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Grăsimi
                </Typography>
              </div>
            </Stack>
            <Typography
              className="mt-5"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Nutriție per porție
            </Typography>
            <hr style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={2}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Calorii
              </Typography>
              <Typography
                className="ms-auto"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {calories} kcal
              </Typography>
            </Stack>
            <hr style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={2}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Carbohidrați
              </Typography>
              <Typography
                className="ms-auto"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {carbs} g
              </Typography>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#797979",
                }}
              >
                Fibre
              </Typography>
              <Typography
                className="ms-auto"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#797979",
                }}
              >
                {fibers} g
              </Typography>
            </Stack>
            <hr style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={2}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Proteine
              </Typography>
              <Typography
                className="ms-auto"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {proteins} g
              </Typography>
            </Stack>
            <hr style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={2}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Grăsimi
              </Typography>
              <Typography
                className="ms-auto"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {fats} g
              </Typography>
            </Stack>
          </div>
        );
      default:
        return "";
    }
  };
  if (isMobile) {
    return (
      <Container fluid style={{ width: "100%", backgroundColor: "#FFFDF2" }}>
        <Row>
          <DashboardSidebar />
        </Row>
        <Row>
          <Col
            style={{
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              backgroundImage: `url(${image})`, // Replace with your image URL
              boxShadow: "0 4px 20px rgba(0, 0, 0, 1)",
              height: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end", // Align items at the bottom
              backgroundSize: "cover", // Ensure the image covers the whole area
              backgroundRepeat: "no-repeat", // Prevent repeating the image
              backgroundPosition: "center", // Center the image
            }}
          >
            <div
              style={{
                // marginBottom: "10px",
                fontFamily: "Poppins",
                color: "#fff",
              }}
            >
              <h2 style={{ margin: 0, fontSize: "24px", fontWeight: "bold" }}>
                {title}
              </h2>
              <h5 style={{ margin: "10px 0", fontSize: "14px" }}>{summary}</h5>
              <h5 style={{ margin: "10px 0", fontSize: "14px" }}>
                <h5 style={{ margin: "10px 0", fontSize: "14px" }}>
                  Autor: {influencer ? influencer : "Bloomplate"}
                </h5>
              </h5>
            </div>

            <Stack gap={2} direction="horizontal" className="mt-2">
              {/* Left aligned icons and text */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaClock style={{ color: "#fff" }} size={14} />{" "}
                {/* Adjust icon size */}
                <span className="ms-1 fs-7" style={{ color: "#fff" }}>
                  {time} m
                </span>{" "}
                {/* Smaller text */}
              </div>
              <div
                className="ms-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaLayerGroup size={14} style={{ color: "#fff" }} />{" "}
                {/* Adjust icon size */}
                <span className="ms-1 fs-7" style={{ color: "#fff" }}>
                  {noIngredients}
                </span>{" "}
                {/* Smaller text */}
              </div>

              <Box
                className="ms-auto"
                style={{
                  width: "32px", // Smaller width
                  height: "32px", // Smaller height
                  backgroundColor: "#FF2C64",
                  borderRadius: "14px", // Smaller border radius
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReplayIcon
                  sx={{
                    color: "#fff",
                    fontSize: "16px", // Smaller icon
                    // transform: "rotate(180deg)", // Rotate icon 180 degrees to the left
                  }}
                />{" "}
              </Box>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              boxShadow: "0px -10px 10px gray",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",

              // paddingLeft: "20px",
              // paddingRight: "20px",
            }}
            className="text-center"
          >
            <ButtonGroup
              style={{
                fontFamily: "Poppins",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <Button
                variant="outline-primary"
                style={{
                  padding: "12px",
                  border: "none",
                  backgroundColor:
                    selectedOption === "ingrediente"
                      ? " #0C3919"
                      : "transparent",
                  color: selectedOption === "ingrediente" ? "#DCF552" : "#000",
                  borderRadius: "20px",
                  marginRight: "30px",
                }}
                onClick={() => handleClick("ingrediente")}
              >
                Ingrediente
              </Button>
              <Button
                variant="outline-primary"
                style={{
                  padding: "12px",
                  border: "none",
                  backgroundColor:
                    selectedOption === "pregatire" ? " #0C3919" : "transparent",
                  color: selectedOption === "pregatire" ? "#DCF552" : "#000",
                  borderRadius: "20px",
                  marginRight: "30px",
                }}
                onClick={() => handleClick("pregatire")}
              >
                Pregatire
              </Button>
              <Button
                variant="outline-primary"
                style={{
                  padding: "12px",
                  border: "none",
                  backgroundColor:
                    selectedOption === "nutritie" ? " #0C3919" : "transparent",
                  color: selectedOption === "nutritie" ? "#DCF552" : "#000",
                  borderRadius: "20px",
                  // marginRight: "30px",
                }}
                onClick={() => handleClick("nutritie")}
              >
                Nutritie
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }}>{renderText()}</div>
      </Container>
    );
  } else {
    return (
      <Container fluid style={{ backgroundColor: "#E7EFDF" }}>
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                N
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            {/* </Nav> */}
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>

          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
                marginRight: 10,
                borderRadius: "40px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <RecipeCard
                dontTruncate={true}
                text={title}
                description={summary}
                influencer={`Autor: ${influencer ? influencer : "Bloomplate"}`}
                noIngredients={noIngredients}
                time={time}
                image={image}
                isDesktop={true}
              />
              <Row>
                <Col lg={4}>
                  <Card
                    style={{
                      fontFamily: "Poppins",
                      // width: "400px",
                      maxHeight: "500px",
                      overflowY: "auto",
                      backgroundColor: "#FFFEF7",
                    }}
                    className="shadow-sm mt-4 p-3"
                  >
                    <Card.Title style={{ fontWeight: "bold" }}>
                      Ingrediente
                    </Card.Title>
                    <Card.Body className="text-center">
                      <Button
                        className="mb-5"
                        variant="outline-dark"
                        style={{
                          borderRadius: "30px", // Rounded corners
                          border: "transparent", // Black border
                          backgroundColor: "#E7EFDF",
                          color: "black", // Black text
                          padding: "8px 16px",
                          fontSize: "14px",
                          width: "100%", // Full-width button
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "8px", // Space between icons and text
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            border: "transparent",
                            backgroundColor: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          <RemoveIcon
                            style={{ color: "#FF2C64" }}
                            onClick={() => handleRemoveClick()}
                          />
                        </div>
                        <span style={{ fontFamily: "Poppins" }}>
                          {servings} portie
                        </span>
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            border: "transparent",
                            backgroundColor: "#fff",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          <AddIcon
                            style={{ color: "#FF2C64" }}
                            onClick={() => handleAddClick()}
                          />
                        </div>
                      </Button>
                      {ingredientNames &&
                        ingredientNames.map((name, index) => (
                          <React.Fragment key={index}>
                            <Row className="align-items-center mb-3 mt-4">
                              <Col xs="auto">
                                <Form.Check type="checkbox" />
                              </Col>
                              <Col lg="auto">
                                <span style={{ fontSize: "12px" }}>{name}</span>
                              </Col>
                              <Col className="text-end">
                                <span style={{ fontSize: "12px" }}>
                                  {amountUnits[index]}
                                </span>
                              </Col>
                            </Row>
                            <hr style={{ border: "1px solid #CFCFCF" }} />
                          </React.Fragment>
                        ))}
                      <Button
                        style={{
                          border: "none",
                          backgroundColor: "#FF2C64",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                        className="btn-block mt-4"
                      >
                        Vezi lista de cumpărături
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={5}>
                  <Card
                    style={{
                      fontFamily: "Poppins",
                      // width: "400px",
                      maxHeight: "500px",
                      overflowY: "auto",
                      backgroundColor: "#FFFEF7",
                    }}
                    className="shadow-sm mt-4 p-4"
                  >
                    <Card.Title style={{ fontWeight: "bold" }}>
                      Pregătire
                    </Card.Title>
                    <Card.Body>
                      {instructions.map((instruction, index) => (
                        <Row className="mt-4 mb-4" key={index}>
                          <Col
                            className="p-3"
                            style={{
                              border: "1px solid #CFCFCF",
                              borderRadius: "10px",
                            }}
                          >
                            <Stack direction="horizontal" gap={2}>
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: "bold",
                                }}
                              >
                                {`Pasul ${index + 1}`}{" "}
                                {/* Display step number */}
                              </Typography>
                              <Form.Check type="checkbox" className="ms-auto" />
                            </Stack>
                            <Typography
                              className="mt-2"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              }}
                            >
                              {instruction} {/* Render the instruction */}
                            </Typography>
                          </Col>
                        </Row>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    style={{
                      fontFamily: "Poppins",
                      // width: "400px",
                      maxHeight: "500px",
                      overflowY: "auto",
                      backgroundColor: "#FFFEF7",
                    }}
                    className="shadow-sm mt-4 p-3"
                  >
                    <Card.Title style={{ fontWeight: "bold" }}>
                      Informații nutriționale
                    </Card.Title>
                    <Card.Body>
                      <Stack direction="horizontal" gap={3}>
                        <div className="text-center">
                          <CircularProgressbar
                            styles={{
                              path: {
                                stroke: "#DC8826",
                              },
                              text: {
                                // Text color
                                fill: "#000",
                                // Text size
                                fontFamily: "Poppins",
                                fontSize: "26px",
                              },
                            }}
                            maxValue={100}
                            value={
                              calculateMacronutrientPercentages(
                                calories,
                                fats,
                                carbs,
                                proteins
                              ).carbPercentage
                            }
                            text={
                              calculateMacronutrientPercentages(
                                calories,
                                fats,
                                carbs,
                                proteins
                              ).carbPercentage + "%"
                            }
                          />
                          <Typography
                            className="mt-2"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            Carbs
                          </Typography>
                        </div>
                        <div className="text-center">
                          <CircularProgressbar
                            styles={{
                              path: {
                                stroke: "#DC26B4",
                              },
                              text: {
                                // Text color
                                fill: "#000",
                                // Text size
                                fontFamily: "Poppins",
                                fontSize: "26px",
                              },
                            }}
                            maxValue={100}
                            value={
                              calculateMacronutrientPercentages(
                                calories,
                                fats,
                                carbs,
                                proteins
                              ).proteinPercentage
                            }
                            text={
                              calculateMacronutrientPercentages(
                                calories,
                                fats,
                                carbs,
                                proteins
                              ).proteinPercentage + "%"
                            }
                          />
                          <Typography
                            className="mt-2"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            Proteină
                          </Typography>
                        </div>
                        <div className="text-center">
                          <CircularProgressbar
                            styles={{
                              path: {
                                stroke: "#26A5DC",
                              },
                              text: {
                                // Text color
                                fill: "#000",
                                // Text size
                                fontFamily: "Poppins",
                                fontSize: "26px",
                              },
                            }}
                            maxValue={100}
                            value={
                              calculateMacronutrientPercentages(
                                calories,
                                fats,
                                carbs,
                                proteins
                              ).fatPercentage
                            }
                            text={
                              calculateMacronutrientPercentages(
                                calories,
                                fats,
                                carbs,
                                proteins
                              ).fatPercentage + "%"
                            }
                          />

                          <Typography
                            className="mt-2"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            Grăsimi
                          </Typography>
                        </div>
                      </Stack>
                      <Typography
                        className="mt-5"
                        style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                      >
                        Nutriție per porție
                      </Typography>
                      <hr style={{ border: "1px solid #CFCFCF" }} />
                      <Stack direction="horizontal" gap={2}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          Calorii
                        </Typography>
                        <Typography
                          className="ms-auto"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "12px",
                          }}
                        >
                          {calories} kcal
                        </Typography>
                      </Stack>
                      <hr style={{ border: "1px solid #CFCFCF" }} />
                      <Stack direction="horizontal" gap={2}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          Carbohidrați
                        </Typography>
                        <Typography
                          className="ms-auto"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "12px",
                          }}
                        >
                          {carbs} g
                        </Typography>
                      </Stack>
                      <Stack direction="horizontal" gap={2}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#797979",
                          }}
                        >
                          Fibre
                        </Typography>
                        <Typography
                          className="ms-auto"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#797979",
                          }}
                        >
                          {fibers} g
                        </Typography>
                      </Stack>
                      <hr style={{ border: "1px solid #CFCFCF" }} />
                      <Stack direction="horizontal" gap={2}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          Proteine
                        </Typography>
                        <Typography
                          className="ms-auto"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "12px",
                          }}
                        >
                          {proteins} g
                        </Typography>
                      </Stack>
                      <hr style={{ border: "1px solid #CFCFCF" }} />
                      <Stack direction="horizontal" gap={2}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          Grăsimi
                        </Typography>
                        <Typography
                          className="ms-auto"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "12px",
                          }}
                        >
                          {fats} g
                        </Typography>
                      </Stack>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default FoodPage;
