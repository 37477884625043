import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/system";
import ButtonSecondary from "../buttons/ButtonSecondary";
import ButtonPrimary from "../buttons/ButtonPrimary";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { Image } from 'react-bootstrap';
import logo from '../../screens/landingPage/logoActual2.png'

// Define styled components
const StyledAppBar = styled(AppBar)({
  backgroundColor: "transparent",
  boxShadow: "none",
  marginTop: 20,
});

const ToolbarContainer = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Logo = styled(Typography)(({ color }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
  fontFamily: "Poppins",
  color: color || "#fff", // Default color if not provided
}));

const NavLinks = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "24px", // Adjust spacing between links
  flexGrow: 1, // Ensure it takes up remaining space
  justifyContent: "space-between", // Space between links and buttons
});

const NavLink = styled(Typography)({
  color: "#fff",
  textDecoration: "none",
  fontFamily: "Poppins",
});

const DrawerHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px",
});

const FullScreenDrawer = styled(Drawer)(({ backgroundColor }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "100%",
    backgroundColor: backgroundColor || "transparent", // Default to transparent if no color is passed
  },
}));

const StyledListItemText = styled(ListItemText)({
  fontFamily: "Poppins",
  color: "#fff",
  margin: "8px 0", // Add margin for spacing between list items
});

// Add styling for the close button
const CloseButton = styled(IconButton)({
  backgroundColor: "#fff",
  color: "#000",
  width: 40,
  height: 40,
  borderRadius: "50%",
});

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

const HomeNavbar = ({ color }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:1000px)");
  const drawerBackgroundColor = isMobile ? "#0C3919" : "transparent";
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpen(!open);
    // navigate("#how")
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  

  return (
    <>
      <StyledAppBar position="static">
        <ToolbarContainer>
          {!isMobile ? <Image src={logo} width={"120"} onClick={() => navigate("/")}/>
          : 
          <Logo variant="h6" color={color}>bloomplate*</Logo>}
          {isMobile ? (
  <>
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={handleDrawerToggle}
      sx={{ display: { xs: "block", md: "none" }, color: color || "#fff" }} // Set burger button color
    >
      <MenuIcon sx={{ color: color || "#fff" }} />
    </IconButton>
    <FullScreenDrawer
      anchor="top"
      open={open}
      onClose={handleDrawerToggle}
      backgroundColor={drawerBackgroundColor} // Pass background color
    >
      <DrawerHeader>
        {/* Logo */}
        <Logo
          sx={{ fontFamily: "Poppins" }}
          variant="h6"
          color={"#fff"} // Set color for the logo
        >
          bloomplate*
        </Logo>
        {/* Close button */}
        <CloseIcon
          sx={{ color: "#fff" }}
          onClick={handleDrawerToggle}
        />
      </DrawerHeader>
      <hr
        style={{
          height: 1,
          backgroundColor: "white",
          border: "none",
        }}
      />
      <List>
        <ListItem button onClick={() => { navigate("/landing-page"); scrollToSection('home'); handleDrawerToggle(); }}>
          <StyledListItemText
            primary={
              <Typography
                sx={{ color: "#fff", fontFamily: "Poppins" }}
              >
                Acasa
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => { navigate("/landing-page"); scrollToSection('how2'); handleDrawerToggle(); }}>
          <StyledListItemText
            primary={
              <Typography
                sx={{ color: "#fff", fontFamily: "Poppins" }}
              >
                Ce facem
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => { navigate("/landing-page"); scrollToSection('how'); handleDrawerToggle(); }}>
          <StyledListItemText
            primary={
              <Typography
                sx={{ color: "#fff", fontFamily: "Poppins" }}
              >
                Cum funcționează
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => { navigate("/landing-page"); scrollToSection('nutritie'); handleDrawerToggle(); }}>
          <StyledListItemText
            primary={
              <Typography
                sx={{ color: "#fff", fontFamily: "Poppins" }}
              >
                Nutriționiști
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => { navigate("/landing-page"); scrollToSection('preturi'); handleDrawerToggle(); }}>
          <StyledListItemText
            primary={
              <Typography
                sx={{ color: "#fff", fontFamily: "Poppins" }}
              >
                Prețuri
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => { navigate("/landing-page"); scrollToSection('faq'); handleDrawerToggle(); }}>
          <StyledListItemText
            primary={
              <Typography
                sx={{ color: "#fff", fontFamily: "Poppins" }}
              >
                FAQ
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={handleDrawerToggle}>
          <StyledListItemText
            primary={<ButtonPrimary onClick={() => navigate("/questionaire/age")} text={"Start chestionar"} />}
          />
        </ListItem>
        <ListItem button onClick={handleDrawerToggle}>
          <StyledListItemText
            primary={<ButtonSecondary onClick={() => navigate("/login")} text={"Log in"} />}
          />
        </ListItem>
      </List>
    </FullScreenDrawer>
  </>
)
 : (
            <NavLinks>
              <div>
                <NavLink
                  sx={{ marginLeft: 10 }}
                  variant="body1"
                  component="a"
                  href="#how2"
                >
                  Ce facem
                </NavLink>
                <NavLink
                  sx={{ marginLeft: 2 }}
                  variant="body1"
                  component="a"
                  href="#how"
                >
                  Cum funcționează
                </NavLink>
                <NavLink
                  sx={{ marginLeft: 2 }}
                  variant="body1"
                  component="a"
                  href="#nutritie"
                >
                  Nutriționiști
                </NavLink>
                <NavLink
                  sx={{ marginLeft: 2 }}
                  variant="body1"
                  component="a"
                  href="#preturi"
                >
                  Prețuri
                </NavLink>
                <NavLink
                  sx={{ marginLeft: 2 }}
                  variant="body1"
                  component="a"
                  href="#faq"
                >
                  FAQ
                </NavLink>
              </div>
              <ButtonContainer>
                <ButtonSecondary onClick={() => navigate("/login")} text={"Log in"} />
                <ButtonPrimary onClick={() => navigate("/questionaire/age")} text={"Start chestionar"} />
              </ButtonContainer>
            </NavLinks>
          )}
        </ToolbarContainer>
      </StyledAppBar>
    </>
  );
};

export default HomeNavbar;
