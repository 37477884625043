import React from "react";
import { Card, Col, Row, Stack, Placeholder, Button } from "react-bootstrap";

const ShoppingListCardSkeleton = () => {
  return (
    <Card
      style={{
        fontFamily: "Poppins",
        maxHeight: "400px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFEF7",
      }}
      className="shadow-sm mt-4"
    >
      <Card.Body style={{ flex: "1 1 auto", overflowY: "auto" }}>
        <Stack gap={2} direction="horizontal">
          <Placeholder as="h5" animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder.Button
            className="ms-auto"
            style={{ background: "none", border: "none", color: "#FF2C64" }}
          >
            <Placeholder xs={4} />
          </Placeholder.Button>
        </Stack>
        <hr style={{ border: "1px solid #CFCFCF" }} />
        {[...Array(4)].map((_, index) => (
          <React.Fragment key={index}>
            <Row className="align-items-center mb-3 mt-4">
              <Col xs="auto">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={1} style={{ width: "20px", height: "20px" }} />
                </Placeholder>
              </Col>
              <Col>
                <Placeholder as="span" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </Col>
              <Col className="text-right">
                <Placeholder as="span" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #CFCFCF" }} />
          </React.Fragment>
        ))}
      </Card.Body>
      <Card.Footer className="text-center">
        <Placeholder.Button
          style={{
            border: "none",
            backgroundColor: "#FF2C64",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          className="w-100"
          size="lg"
        >
          <Placeholder xs={6} />
        </Placeholder.Button>
      </Card.Footer>
    </Card>
  );
};

export default ShoppingListCardSkeleton;
