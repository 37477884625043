import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  InputGroup,
  Stack, Image
} from "react-bootstrap";
import imagine from "./imagine.jpeg";
import { Typography, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, styled } from "@mui/system";
import Tick from "../../components/buttons/Tick";
import ButtonForm from "../../components/buttons/ButtonForm";
import Back from "../../components/buttons/Back";
import BoxNumber from "../../components/buttons/BoxNumber";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import BoxNumberOutlined from "../../components/buttons/BoxNumberOutlined";
import { useNavigate } from "react-router-dom";
import RegisterState from "../../context/RegisterState";
import logo from "./logoActual2.png";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const QuestionaireKcal = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const registerState = useContext(RegisterState);
  // const [plan, setPlan] = useState(0);

  const handleSubmit = (plan) => {
    registerState.setPlan(plan);
    navigate("/questionaire/register");
  };

  return (
    <Container
      fluid
      style={{
        minHeight: "100vh",
        display: "flex",
        padding: 0,
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Row style={{ flex: "1 1 auto" }}>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              overflowY: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className=" align-items-center text-white">
                <Col lg={10}>
                <Image
                    src={logo}
                    width={"120"}
                    onClick={() => navigate("/")}
                  />

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col lg={8} xs={12} style={{ backgroundColor: "#FFFDF2" }}>
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            {!isMobile ? (
              <Stack direction="horizontal" alignItems="center">
                <Back onClick={() => navigate("/questionaire/kcal")} />

                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumberOutlined text={"1"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"3"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"4"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <BoxNumber text={"8"} />

                <ButtonPrimary
                  onClick={() => navigate("/questionaire/register")}
                  className="ms-auto"
                  text="Continuă"
                />
              </Stack>
            ) : (
              <Stack direction="horizontal" alignItems="center">
                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumberOutlined text={"1"} />
                  <Box sx={{ width: 10, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"3"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"4"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <BoxNumber text={"8"} />
              </Stack>
            )}
          </Row>

          <Row style={{ padding: 20, marginLeft: !isMobile ? 80 : 0 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Yuhuu, mai ai doar un pas pentru a încerca Bloomplate!
            </Typography>
            <Typography
              className="mt-4"
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                color: "#000",
              }}
            >
              Alege planul potrivit pentru nevoile tale:
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={1} className="mt-4">
              <Grid item xs={12} sm={4}>
                <Card
                  className="p-4"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#FFFDF2",
                    height: "300px",
                  }}
                >
                  <Row>
                    <Col>
                      <Typography
                        component="div"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "0.5rem",
                          fontFamily: "Poppins",
                          fontSize: "20px",
                        }}
                      >
                        1-Săptămâna Free
                      </Typography>
                      <Typography
                        className="text-center mb-2"
                        sx={{
                          backgroundColor: "#F3A7BB",
                          borderRadius: "8px",
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        }}
                      >
                        Abonament de 1 lună
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          textDecoration: "line-through",
                          color: "#999",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                        }}
                      >
                        89,99 RON/lună
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          fontWeight: "bold",
                          color: "#FF2C64",
                          fontFamily: "Poppins",
                          fontSize: "30px",
                        }}
                      >
                        49,99 RON/lună
                      </Typography>
                      <Button
                        className="mt-2"
                        style={{
                          backgroundColor: "#DDF652",
                          border: "none",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          fontWeight: 400,
                          color: "#000",
                          fontFamily: "Poppins",
                          textTransform: "none",
                        }}
                        onClick={() => handleSubmit(1)}
                      >
                        Încearcă gratuit
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card
                  className="p-4"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#FFFDF2",
                    height: "300px",
                  }}
                >
                  <Row>
                    <Col>
                      <Typography
                        component="div"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "0.5rem",
                          fontFamily: "Poppins",
                          fontSize: "20px",
                        }}
                      >
                        Planul de 3 luni
                      </Typography>
                      <Typography
                        className="text-center mb-2"
                        sx={{
                          backgroundColor: "#F3A7BB",
                          borderRadius: "8px",
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        }}
                      >
                        Abonament de 3 luni
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          color: "#999",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                        }}
                      >
                        119.97 RON / 3 luni
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          fontWeight: "bold",
                          color: "#FF2C64",
                          fontFamily: "Poppins",
                          fontSize: "30px",
                        }}
                      >
                        39,99 RON/lună
                      </Typography>
                      <Button
                        className="mt-2"
                        style={{
                          backgroundColor: "#DDF652",
                          border: "none",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          fontWeight: 400,
                          color: "#000",
                          fontFamily: "Poppins",
                          textTransform: "none",
                        }}
                        onClick={() => handleSubmit(2)}
                      >
                        Încearcă gratuit
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card
                  className="p-4 mb-5"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#FFFDF2",
                    height: "300px",
                  }}
                >
                  <Row>
                    <Col>
                      <Typography
                        component="div"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "0.5rem",
                          fontFamily: "Poppins",
                          fontSize: "20px",
                        }}
                      >
                        Planul de 1 an
                      </Typography>
                      <Typography
                        className="text-center mb-2"
                        sx={{
                          backgroundColor: "#F3A7BB",
                          borderRadius: "8px",
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        }}
                      >
                        Abonament de 12 luni
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          color: "#999",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                        }}
                      >
                        239,88 RON / an
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          fontWeight: "bold",
                          color: "#FF2C64",
                          fontFamily: "Poppins",
                          fontSize: "30px",
                        }}
                      >
                        19,99 RON/lună
                      </Typography>

                      <Button
                        className="mt-2"
                        style={{
                          backgroundColor: "#DDF652",
                          border: "none",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          fontWeight: 400,
                          color: "#000",
                          fontFamily: "Poppins",
                          textTransform: "none",
                        }}
                        onClick={() => handleSubmit(3)}
                      >
                        Încearcă gratuit
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Grid>
            </Grid>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default QuestionaireKcal;
