import React, { useContext, useState } from "react";
import { Button, Offcanvas, Navbar, Container, Row } from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import AssignmentIcon from '@mui/icons-material/Assignment';

const DashboardSidebar = () => {
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isRecommendationsClicked, setIsRecommendationsClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false); // State for mobile Offcanvas
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <>
      {/* Navbar for Mobile */}
      <Navbar
        // bg="light"
        expand="lg"
        className="d-lg-none w-100"
        style={{ backgroundColor: "#E9EFE3", padding: 20 }}
      >
        <Navbar.Toggle
          style={{ border: "none" }} // Removes the border
          aria-controls="offcanvasNavbar"
          onClick={() => setShowSidebar(true)}
        >
          <MenuIcon sx={{ color: "#117233", borderColor: "#117233" }} />
        </Navbar.Toggle>
        <Navbar.Brand
          className="mx-auto"
          style={{ fontFamily: "Poppins", fontWeight: "bold" }}
        >
          bloomplate*
        </Navbar.Brand>
        <Button
          variant="light"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <PersonIcon
            onClick={() => navigate("/profile/settings")}
            sx={{ color: "#117233" }}
          />
        </Button>
      </Navbar>

      {/* Offcanvas for Mobile Sidebar */}
      <Offcanvas
        className="w-100"
        show={showSidebar}
        onHide={() => setShowSidebar(false)}
        style={{ width: "100%", maxWidth: "100vw", backgroundColor: "#E9EFE3" }}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{ fontFamily: "Poppins", fontWeight: "bold" }}
          >
            bloomplate*
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
              <Button
                className="mb-3 p-3"
                variant="light"
                style={{
                  textAlign: "left",
                  backgroundColor: isDashboardClicked
                    ? "#117233"
                    : "transparent",
                  border: isDashboardClicked ? "none" : "1px solid #000",
                  color: isDashboardClicked ? "#fff" : "#000",
                  paddingLeft: "15px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  borderRadius: 10,
                }}
                onClick={() => navigate("/dashboard")}
              >
                <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
              </Button>
              <Button
                className="mb-3 p-3"
                variant="light"
                style={{
                  textAlign: "left",
                  backgroundColor: isCumpClicked ? "#117233" : "transparent",
                  border: isCumpClicked ? "none" : "1px solid #000",
                  color: isCumpClicked ? "#fff" : "#000",
                  paddingLeft: "15px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  borderRadius: 10,
                }}
                onClick={() => navigate("/dashboard/shopping-list")}
              >
                <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
              </Button>
              <Button
                className="mb-3 p-3"
                variant="light"
                style={{
                  textAlign: "left",
                  backgroundColor: isProfilClicked ? "#117233" : "transparent",
                  border: isProfilClicked ? "none" : "1px solid #000",
                  color: isProfilClicked ? "#fff" : "#000",
                  paddingLeft: "15px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  borderRadius: 10,
                }}
                onClick={() => navigate("/profile/settings")}
              >
                <PersonIcon sx={{ marginRight: "20px" }} /> Profil
              </Button>
              <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked ? "#117233" : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandari
            </Button>
              <Button
                className="mb-4 p-3"
                variant="light"
                style={{
                  textAlign: "left",
                  backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                  border: isLogOutClicked ? "none" : "1px solid #000",
                  color: isLogOutClicked ? "#fff" : "#000",
                  paddingLeft: "15px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  borderRadius: 10,
                }}
                onClick={() => logout()}
              >
                <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
              </Button>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Sidebar for Desktop */}
      <div
        className="d-none d-lg-flex flex-column"
        style={{
          backgroundColor: "#E7EFDF",
          position: "fixed", // Fix to the left side
          left: 0, // Align to the left
          top: 0, // Start at the top
          bottom: 0, // Stretch to the bottom
          width: "250px", // Width as before
          paddingTop: "20px",
          paddingLeft: "15px",
          zIndex: 999, // Ensure it stays above other content
          paddingRight: 20,
        }}
      >
        <h4
          style={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            color: "#0C3919",
          }}
        >
          bloomplate*
        </h4>

        <Button
          className="mt-5 p-3 mb-3"
          variant="light"
          style={{
            textAlign: "left",
            backgroundColor: isDashboardClicked ? "#117233" : "transparent",
            border: isDashboardClicked ? "none" : "1px solid #000",
            color: isDashboardClicked ? "#fff" : "#000",
            paddingLeft: "15px",
            fontFamily: "Poppins",
            fontWeight: 500,
            borderRadius: 10,
          }}
          onClick={() => setIsDashboardClicked(!isDashboardClicked)}
        >
          <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
        </Button>

        <Button
          className="mb-3 p-3"
          variant="light"
          style={{
            textAlign: "left",
            backgroundColor: isCumpClicked ? "#117233" : "transparent",
            border: isCumpClicked ? "none" : "1px solid #000",
            color: isCumpClicked ? "#fff" : "#000",
            paddingLeft: "15px",
            fontFamily: "Poppins",
            fontWeight: 500,
            borderRadius: 10,
          }}
          onClick={() => setIsCumpClicked(!isCumpClicked)}
        >
          <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
        </Button>

        <Button
          className="mb-3 p-3"
          variant="light"
          style={{
            textAlign: "left",
            backgroundColor: isProfilClicked ? "#117233" : "transparent",
            border: isProfilClicked ? "none" : "1px solid #000",
            color: isProfilClicked ? "#fff" : "#000",
            paddingLeft: "15px",
            fontFamily: "Poppins",
            fontWeight: 500,
            borderRadius: 10,
          }}
          onClick={() => setIsProfilClicked(!isProfilClicked)}
        >
          <PersonIcon sx={{ marginRight: "20px" }} /> Profil
        </Button>

        <Button
          className="mb-4 mt-auto p-3"
          variant="light"
          style={{
            textAlign: "left",
            backgroundColor: isLogOutClicked ? "#117233" : "transparent",
            border: isLogOutClicked ? "none" : "1px solid #000",
            color: isLogOutClicked ? "#fff" : "#000",
            paddingLeft: "15px",
            fontFamily: "Poppins",
            fontWeight: 500,
            borderRadius: 10,
          }}
          onClick={() => setIsLogOutClicked(!isLogOutClicked)}
        >
          <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
        </Button>
      </div>
    </>
  );
};

export default DashboardSidebar;
