// Imports
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Spinner } from "react-bootstrap"; // Import the Spinner component

/** Generate the RoleBasedRedirect component.
 * This component concerns the first page the user sees
 * depending on their role or whether or not
 * they have been authenticated.
 */
const RoleBasedRedirect = () => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  // If still loading, return a loading spinner
  if (isLoading) {
    return (
      <div style={styles.spinnerContainer}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  // Redirect based on authentication status
  return isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/landing-page" />;
};

const styles = {
  spinnerContainer: {
    display: 'inline-flex', // Use inline-flex for inline display
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height to center vertically
    width: '100%', // Full width to center horizontally
  },
};

export default RoleBasedRedirect;
