import { Typography } from "@mui/material";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';import "./DashboardSidebar.css";

import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./DashboardSidebar.css";
import RecipeCardBig from "../../components/cards/RecipeCardBig";
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import { Box, borderRadius, color } from "@mui/system";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";

const ShoppingListDesktop = ({}) => {
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);

  return (
    <Container fluid>
      <Row>
        {/* Lateral Navigation */}
        <Col
          lg={2}
          // xs={2}
          // md={2}
          style={{
            backgroundColor: "#E7EFDF",
            height: "100vh",
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            position: "sticky", // Make it sticky
            top: 0, // Stick to the top
            overflowY: "auto", // Allow vertical scrolling if needed
            // justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#0C3919",
            }}
          >
            bloomplate*
          </Typography>
          {/* <Nav className="d-flex flex-column"> */}

          <Button
            className="mt-5 p-3 mb-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isDashboardClicked ? "#117233" : "transparent",
              border: isDashboardClicked ? "none" : "1px solid #000",
              color: isDashboardClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsDashboardClicked(!isDashboardClicked)}
          >
            <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
          </Button>
          <Button
            className="mb-3 p-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isCumpClicked ? "#117233" : "transparent",
              border: isCumpClicked ? "none" : "1px solid #000",
              color: isCumpClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsCumpClicked(!isCumpClicked)}
          >
            <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
          </Button>
          <Button
            className="mb-3 p-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isProfilClicked ? "#117233" : "transparent",
              border: isProfilClicked ? "none" : "1px solid #000",
              color: isProfilClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsProfilClicked(!isProfilClicked)}
          >
            <PersonIcon sx={{ marginRight: "20px" }} /> Profil
          </Button>
          {/* </Nav> */}
          <Button
            className="mb-4 mt-auto p-3"
            variant="light"
            style={{
              textAlign: "left",
              backgroundColor: isLogOutClicked ? "#117233" : "transparent",
              border: isLogOutClicked ? "none" : "1px solid #000",
              color: isLogOutClicked ? "#fff" : "#000",
              paddingLeft: "15px",
              fontFamily: "Poppins",
              fontWeight: 500,
              borderRadius: 10,
            }}
            onClick={() => setIsLogOutClicked(!isLogOutClicked)}
          >
            <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
          </Button>
        </Col>

        <Col lg={10} className="p-4" style={{backgroundColor: '#FFFEF7'}}>
          <Typography
            sx={{ fontFamily: "Poppins", fontSize: "30px", fontWeight: "bold" }}
          >
            Cumpărături
          </Typography>

          
          <Row className="mt-4">
          <Typography
            className="mb-4"
            sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "bold" }}
          >
            Categorie
          </Typography>
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />

          </Row>
          
        
          <Row className="mt-4">
          <Typography
            className="mb-4"
            sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "bold" }}
          >
            Categorie
          </Typography>
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />

          </Row>
          <Row className="mt-4">
          <Typography
            className="mb-4"
            sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "bold" }}
          >
            Categorie
          </Typography>
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />
            <Stack direction="horizontal" gap={3}>
                <Form.Check type="checkbox"/>
                <span className="ms-4" style={{fontFamily: 'Poppins'}}>Aliment</span>
                <span className="ms-auto">200 g</span>
            </Stack>
            <hr className="mt-2 px-4" style={{ border: "1px solid #CFCFCF" }} />

          </Row>
          
          <Row style={{
          }}className="mt-4">
    <Stack direction="horizontal" gap={2} className="w-100">
        <ButtonPrimary text="Comandă acum" />
        <div className="ms-auto d-flex gap-3">
            <Box
                sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#FF2C64",
                    borderRadius: "4px",
                    // border: "1px solid #FF2C64",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer", // Add cursor pointer
                }}
            >
                <DeleteIcon fontSize="medium" sx={{ color: "#fff" }} />
            </Box>
            <Box
                sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#FF2C64",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <AddIcon sx={{ color: "#fff" }} />
            </Box>
        </div>
    </Stack>
</Row>

        </Col>
      </Row>
    </Container>
  );
};

export default ShoppingListDesktop;
