import React, { useRef } from "react";
import { Row, Col, Container, Stack, Button } from "react-bootstrap";
import StyledText from "../../components/texts/StyledTextHalfColored";
import { Typography } from "@mui/material";
import Slider from "react-slick";
import oana from "./oana.png";
import teodora from "./teodora.png";
import olivia from "./olivia.png";
import SpecialistCard from "../../components/cards/SpecialistCard";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

const LandingPageHomework = () => {
  const navigate = useNavigate();
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const cards = [
    {
      position: "MEDIC DE FAMILIE",
      name: "Dr. Oana Ciuhureanu",
      image: oana,
      description: "Dr Oana Ciuhureanu, medic de familie, master farmacie, nutriție și siguranţă alimentară, formator nutriţie generală şi consilier psihologia nutriţiei, couch."
    },
    {
      position: "NUTRIȚIONIST DIETETICIAN",
      name: "Teodora Rădulescu",
      image: teodora,
      description: "Teodora este nutriționist-dietetician licențiat și masterand în Nutriție Personalizată și Comunitară. Lucrează ca consultant în nutriție sportivă pentru USA Fitness. În practica dietetică, evaluează aspectele medicale, nutriționale și emoționale ale schimbării stilului de viață."
    },
    {
      position: "NUTRIȚIONIST SPECIALIZAT",
      name: "Olivia Niță",
      image: olivia,
      description: "Olivia este specializată în Nutriție Aplicată pentru Remodelare Corporală și în crearea de rețete echilibrate din punct de vedere nutrițional. Este pasionată de un stil de viață sănătos și de îmbunătățirea calității vieții prin alimentație și nutriție echilibrată."
    },
  ];
  const settings = {
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    dots: false,
    arrows: false,
    centerMode: true, // Enable center mode
    centerPadding: "22%", // Adjust padding to show half of the next slide
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0%",
          // centerPadding: "10%", // Adjust padding for mobile view
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: "15%", // Adjust padding for tablet view
        },
      },
    ],
  };

  return (
    <Container id="nutritie" style={{ marginTop: 160 }}>
      <Row className="text-center">
        <StyledText
          color={"#DCF552"}
          fontSize={40}
          fontWeight={700}
          textNormal={"Ne facem temele ca tu să stai"}
          textUnderlined={"liniștită"}
        />
      </Row>
      <Stack className="mt-5">
        <div className="ms-auto">
          <Button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            onClick={previous}
          >
            <ChevronLeftIcon sx={{ color: "#FF2C64", fontSize: "32px" }} />
          </Button>
          <Button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            onClick={next}
          >
            <ChevronRightIcon
              sx={{ color: "#FF2C64", fontSize: "32px", marginLeft: "8px" }}
            />
          </Button>
        </div>
      </Stack>
      <Row style={{ marginTop: 60 }}>
        <Col lg={3}>
          <Typography
            className="mb-3"
            sx={{ fontWeight: "bold", fontFamily: "Poppins", fontSize: 18 }}
          >
            Farfurii pregătite cu cap, nu după pofte.
          </Typography>
          <Typography
            className="mb-3"
            sx={{ fontFamily: "Poppins", fontSize: 18, fontWeight: 300 }}
          >
            Uită de diete restrictive și sfaturi contradictorii. Echipa noastră
            de nutriționiști certificați analizează cu atenție fiecare rețetă
            înainte de a ajunge la tine în farfurie.
          </Typography>
        </Col>
        <Col lg={9}>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            <SpecialistCard width={"100%"} specialist={cards[0]} />
            <SpecialistCard width={"100%"} specialist={cards[1]} />
            <SpecialistCard width={"100%"} specialist={cards[2]} />
          </Slider>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
          left: 0,
        }}
      >
        <ButtonPrimary text="Start chestionar" onClick={() => navigate("/questionaire/age")} />
      </div>
    </Container>
  );
};

export default LandingPageHomework;
